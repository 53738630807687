// ================================================================================================
//  File Name: colors.scss
//  Description: Common color file to include color paletter and colors file, its qiore good to
//  have all color classes in a separate file as it's quite heavy.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core
// ------------------------------

// Import variables and mixins
@import 'bootstrap/scss/mixins/gradients';
@import 'core/mixins/hex2rgb';

//box shadow for alert's heading
@import 'core/mixins/alert';

// Color system, always load last
// ------------------------------
@import 'core/colors/palette';
@import 'core/colors/palette-gradient.scss';

$navbar-color: #f7f7f7;
$color-planning: #fee101;
$color-pending: #ffa15c;
$color-on-progress: #4192d9;
$color-delivered: #45bf55;
$color-completed: #595959;

:root {
  --color-planning: #fee101;
  --color-pending: #ffa15c;
  --color-on-progress: #4192d9;
  --color-delivered: #45bf55;
  --color-completed: #595959;
  --color-primary: #c0262d;
  --color-white: #fff;

  --color-success: #28a745;
  --color-danger: #dc3545;

  /** info - CUSTOM **/
  --color-primary-darken: #c4302b;
  --color-primary-default: #e93e3f;
  --color-primary-light: #ea5455;
  --color-primary-lighter: #e88787;
  --color-primary-transparent: #fae7e7;

  /** info - CUSTOM **/
  --color-info-darken: #0699ab;
  --color-info-default: #07b2c7;
  --color-info-light: #01c7df;
  --color-info-lighter: #00d9f3;
  --color-info-transparent: #e5fbfe;

  /** info - CUSTOM **/
  --color-success-darken: #51a351;
  --color-success-default: #5db45d;
  --color-success-light: #62c662;
  --color-success-lighter: #6ad76a;
  --color-success-transparent: #e0f4e0;

  /** warning - CUSTOM **/
  --color-warning-darken: #f97900;
  --color-warning-default: #ff8e23;
  --color-warning-light: #ffa146;
  --color-warning-lighter: #ffb46c;
  --color-warning-transparent: #fff0e2;

  /** success **/
  --color-success: #2dd36f;
  --color-success-rgb: 45, 211, 111;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #28ba62;
  --color-success-tint: #42d77d;

  /** warning **/
  --color-warning: #ffc409;
  --color-warning-rgb: 255, 196, 9;
  --color-warning-contrast: #000000;
  --color-warning-contrast-rgb: 0, 0, 0;
  --color-warning-shade: #e0ac08;
  --color-warning-tint: #ffca22;

  /** yellow **/
  --color-yellow-light: #ffe752;
  --color-yellow-transparent: #fff7de;

  /** danger **/
  --color-danger: #eb445a;
  --color-danger-rgb: 235, 68, 90;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #cf3c4f;
  --color-danger-tint: #ed576b;
}
