$color_1: #dc3545;
$color_2: #6c757d;
$color_3: #212529;
$color_4: #ffffff;
$color_5: #495057;
$color_7: #17a2b8;
$color_8: #28a745;
$color_9: #ffc107;
$color_10: #6f42c1;
$color_11: #0069d9;
$color_12: #fff;
$color_13: #003e80;
$color_14: inherit;
$color_15: rgba(0, 0, 0, 0.9);
$color_16: rgba(0, 0, 0, 0.5);
$color_17: rgba(0, 0, 0, 0.7);
$color_18: #004085;
$color_19: #155724;
$color_20: #856404;
$color_21: #721c24;
$color_22: #efefef;
$color_23: #f8f9fa;
$font-family-icon: "PrimeIcons";
$background-color_1: rgba(0, 0, 0, 0.4);
$background-color_2: #efefef;
$background-color_3: transparent;
$background-color_4: #ffffff;
$background-color_5: #e9ecef;
$background-color_6: #007bff;
$background-color_7: $border-color;
$background-color_8: #0062cc;
$background-color_9: inherit;
$background-color_10: rgba(0, 0, 0, 0.5);
$background-color_11: rgba(255, 255, 255, 0.1);
$background-color_12: #6c757d;
$background-color_13: #28a745;
$background-color_14: #17a2b8;
$background-color_15: #ffc107;
$background-color_16: #dc3545;
$border-color_1: #ced4da;
$border-color_2: #007bff;
$border-color_3: #dc3545;
$border-color_4: transparent;
$border-color_5: #0062cc;
$border-color_6: #ffffff;
$border-color_7: #545b62;
$border-color_8: #4e555b;
$border-color_9: #0069d9;
$border-color_10: #6c757d;
$border-color_11: #5a6268;
$border-color_12: #117a8b;
$border-color_13: #1e7e34;
$border-color_14: #1c7430;
$border-color_15: #d39e00;
$border-color_16: #c69500;
$border-color_17: #58349a;
$border-color_18: #4d2e87;
$border-color_19: #bd2130;
$border-color_20: #b21f2d;
$border-color_21: $border-color;
$border-color_22: #ffffff #ffffff $border-color #ffffff;
$border-color_23: $border-color $border-color #ffffff $border-color;
$border-color_24: rgba(255, 255, 255, 0);
$border-color_25: rgba(0, 0, 0, 0);
$border-top-color_1: #ffffff;
$border-top-color_2: rgba(0, 0, 0, 0.2);
$border-top-color_3: #212529;
$border-right-color_1: #212529;
$border-bottom-color_1: #ffffff;
$border-bottom-color_2: rgba(0, 0, 0, 0.2);
$border-bottom-color_3: #212529;
$border-left-color_1: #212529;
$outline-color_1: rgba(38, 143, 255, 0.5);

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}
.p-component {
  &:disabled {
    opacity: 0.65;
  }
}
.p-component-overlay {
  background-color: $background-color_1;
  transition-duration: 0.15s;
}
.p-disabled {
  opacity: 0.65;
}
.p-error {
  color: $color_1;
}
.p-text-secondary {
  color: $color_2;
}
.pi {
  font-size: 1rem;
}
.p-link {
  font-size: 1rem;
  border-radius: 4px;
  &:focus {
    outline: 0 none;
    outline-offset: 0;

  }
}
//.p-component-overlay-enter {
//  animation: p-component-overlay-enter-animation 150ms forwards;
//}
//.p-component-overlay-leave {
//  animation: p-component-overlay-leave-animation 150ms forwards;
//}
.p-autocomplete {
  .p-autocomplete-loader {
    right: 0.75rem;
  }
  .p-autocomplete-multiple-container {
    padding: 0.25rem 0.75rem;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;

      border-color: $border-color_2;
    }
    .p-autocomplete-input-token {
      padding: 0.25rem 0;
      input {
        font-size: 1rem;
        color: $color_3;
        padding: 0;
        margin: 0;
      }
    }
    .p-autocomplete-token {
      padding: 0.25rem 0.75rem;
      margin-right: 0.5rem;
      background: #007bff;
      color: $color_4;
      border-radius: 4px;
      .p-autocomplete-token-icon {
        margin-left: 0.5rem;
      }
    }
  }
}
.p-autocomplete.p-autocomplete-dd {
  .p-autocomplete-loader {
    right: 3.107rem;
  }
}
p-autocomplete.ng-dirty.ng-invalid {
  .p-autocomplete {
    .p-inputtext {
      border-color: $border-color_3;
    }
  }
}
.p-autocomplete-panel {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  .p-autocomplete-items {
    &:not(.p-autocomplete-virtualscroll) {
      padding: 0.5rem 0;
    }
    .p-autocomplete-item {
      margin: 0;
      padding: 0.5rem 1.5rem;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 0;
      &:hover {
        color: $color_3;
        background: #e9ecef;
      }
    }
    .p-autocomplete-item.p-highlight {
      color: $color_4;
      background: #007bff;
    }
    .p-autocomplete-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
      background: transparent;
    }
    .p-autocomplete-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: $color_3;
      background: #ffffff;
      font-weight: 600;
    }
  }
  .p-autocomplete-items.p-autocomplete-virtualscroll {
    .cdk-virtual-scroll-content-wrapper {
      padding: 0.5rem 0;
    }
  }
}
p-autocomplete.p-autocomplete-clearable {
  .p-inputtext {
    padding-right: 2.5rem;
  }
  .p-autocomplete-clear-icon {
    color: $color_5;
    right: 0.75rem;
  }
}
p-autocomplete.p-autocomplete-clearable.p-autocomplete-dd {
  .p-autocomplete-clear-icon {
    color: $color_5;
    right: 3.107rem;
  }
}
p-calendar.ng-dirty.ng-invalid {
  .p-calendar {
    .p-inputtext {
      border-color: $border-color_3;
    }
  }
}
.p-datepicker {
  padding: 0;
  background: #ffffff;
  color: $color_3;
  border: 1px solid #ced4da;
  border-radius: 4px;
  &:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    .p-datepicker-header {
      background: #efefef;
    }
  }
  .p-datepicker-header {
    padding: 0.5rem;
    color: $color_3;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid $border-color;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-datepicker-prev {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .p-datepicker-next {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .p-datepicker-title {
      line-height: 2rem;
      .p-datepicker-year {
        color: $color_3;
        transition: box-shadow 0.15s;
        font-weight: 600;
        padding: 0.5rem;
        &:enabled {
          &:hover {
            color: $primary;
          }
        }
      }
      .p-datepicker-month {
        color: $color_3;
        transition: box-shadow 0.15s;
        font-weight: 600;
        padding: 0.5rem;
        margin-right: 0.5rem;
        &:enabled {
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
  table {
    font-size: 1rem;
    margin: 0.5rem 0;
    th {
      padding: 0.5rem;
      span {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    td {
      padding: 0.5rem;
      span {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 4px;
        transition: box-shadow 0.15s;
        border: 1px solid transparent;
        &:focus {
          outline: 0 none;
          outline-offset: 0;

        }
      }
      span.p-highlight {
        color: $color_4;
        background: #007bff;
      }
    }
    td.p-datepicker-today {
      span {
        background: #ced4da;
        color: $color_3;
        border-color: $border-color_4;
      }
      span.p-highlight {
        color: $color_4;
        background: #007bff;
      }
    }
  }
  .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid $border-color;
    .p-button {
      width: auto;
    }
  }
  .p-timepicker {
    border-top: 1px solid $border-color;
    padding: 0.5rem;
    button {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
      &:last-child {
        margin-top: 0.2em;
      }
    }
    span {
      font-size: 1.25rem;
    }
    div {
      padding: 0 0.5rem;
    }
  }
  .p-monthpicker {
    margin: 0.5rem 0;
    .p-monthpicker-month {
      padding: 0.5rem;
      transition: box-shadow 0.15s;
      border-radius: 4px;
    }
    .p-monthpicker-month.p-highlight {
      color: $color_4;
      background: #007bff;
    }
  }
  .p-yearpicker {
    margin: 0.5rem 0;
    .p-yearpicker-year {
      padding: 0.5rem;
      transition: box-shadow 0.15s;
      border-radius: 4px;
    }
    .p-yearpicker-year.p-highlight {
      color: $color_4;
      background: #007bff;
    }
  }
  &:not(.p-disabled) {
    table {
      td {
        span {
          &:not(.p-highlight) {
            &:not(.p-disabled) {
              &:hover {
                background: #e9ecef;
              }
              &:focus {
                outline: 0 none;
                outline-offset: 0;

              }
            }
          }
        }
      }
    }
    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          &:not(.p-highlight) {
            &:hover {
              background: #e9ecef;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
      }
    }
    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          &:not(.p-highlight) {
            &:hover {
              background: #e9ecef;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
      }
    }
  }
}
.p-datepicker.p-datepicker-timeonly {
  .p-timepicker {
    border-top: 0 none;
  }
}
.p-datepicker.p-datepicker-multiple-month {
  .p-datepicker-group {
    border-left: 1px solid $border-color;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    &:first-child {
      padding-left: 0;
      border-left: 0 none;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
p-calendar.p-calendar-clearable {
  .p-inputtext {
    padding-right: 2.5rem;
  }
  .p-calendar-clear-icon {
    color: $color_5;
    right: 0.75rem;
  }
}
p-calendar.p-calendar-clearable.p-calendar-w-btn {
  .p-calendar-clear-icon {
    color: $color_5;
    right: 3.107rem;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $border-color_2;
  }
  .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.75rem;
    &:enabled {
      &:focus {
        outline: 0 none;
        box-shadow: none;
      }
    }
  }
  .p-cascadeselect-label.p-placeholder {
    color: $color_2;
  }
  .p-cascadeselect-trigger {
    background: transparent;
    color: $color_5;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.p-cascadeselect-panel {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  .p-cascadeselect-items {
    padding: 0.5rem 0;
    .p-cascadeselect-item {
      margin: 0;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 0;
      .p-cascadeselect-item-content {
        padding: 0.5rem 1.5rem;
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: $color_3;
            background: #e9ecef;
          }
        }
      }
      .p-cascadeselect-group-icon {
        font-size: 0.875rem;
      }
    }
    .p-cascadeselect-item.p-highlight {
      color: $color_4;
      background: #007bff;
    }
  }
}
p-cascadeselect.ng-dirty.ng-invalid {
  .p-cascadeselect {
    border-color: $border-color_3;
  }
}
.p-input-filled {
  .p-cascadeselect {
    background: #efefef;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_2;
    }
  }
  .p-checkbox {
    .p-checkbox-box {
      background-color: $background-color_2;
    }
    .p-checkbox-box.p-highlight {
      background: #007bff;
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: $background-color_2;
        }
      }
      .p-checkbox-box.p-highlight {
        &:hover {
          background: #0062cc;
        }
      }
    }
  }
  .p-dropdown {
    background: #efefef;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_2;
      .p-inputtext {
        background-color: $background-color_3;
      }
    }
  }
  .p-inputtext {
    background-color: $background-color_2;
    &:enabled {
      &:hover {
        background-color: $background-color_2;
      }
      &:focus {
        background-color: $background-color_2;
      }
    }
  }
  .p-multiselect {
    background: #efefef;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_2;
    }
  }
  .p-radiobutton {
    .p-radiobutton-box {
      background-color: $background-color_2;
      &:not(.p-disabled) {
        &:hover {
          background-color: $background-color_2;
        }
      }
    }
    .p-radiobutton-box.p-highlight {
      background: #007bff;
      &:not(.p-disabled) {
        &:hover {
          background: #0062cc;
        }
      }
    }
  }
  .p-treeselect {
    background: #efefef;
    &:not(.p-disabled) {
      &:hover {
        background-color: $background-color_2;
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: $background-color_2;
    }
  }
}
p-cascadeselect.p-cascadeselect-clearable {
  .p-cascadeselect-label {
    padding-right: 0.75rem;
  }
  .p-cascadeselect-clear-icon {
    color: $color_5;
    right: 2.357rem;
  }
}
.p-checkbox {
  width: 20px;
  height: 20px;
  .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: $color_3;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    .p-checkbox-icon {
      transition-duration: 0.15s;
      color: $color_4;
      font-size: 14px;
    }
  }
  .p-checkbox-box.p-highlight {
    border-color: $primary;
    background: $primary;
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $border-color_1;
      }
    }
    .p-checkbox-box.p-focus {
      outline: 0 none;
      outline-offset: 0;

      border-color: $border-color_2;
    }
    .p-checkbox-box.p-highlight {
      &:hover {
        border-color: $primary;
        background: $primary;
        color: $color_4;
      }
    }
  }
}
p-checkbox.ng-dirty.ng-invalid {
  .p-checkbox {
    .p-checkbox-box {
      border-color: $border-color_3;
    }
  }
}
.p-checkbox-label {
  margin-left: 0.5rem;
}
.p-highlight {
  .p-checkbox {
    .p-checkbox-box {
      border-color: $border-color_6;
    }
  }
  .p-radiobutton {
    .p-radiobutton-box {
      border-color: $border-color_6;
    }
  }
  .p-rating {
    .p-rating-icon {
      color: $color_4;
    }
  }
}
.p-chips {
  .p-chips-multiple-container {
    padding: 0.25rem 0.75rem;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_1;
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;

      border-color: $border-color_2;
    }
    .p-chips-token {
      padding: 0.25rem 0.75rem;
      margin-right: 0.5rem;
      background: #007bff;
      color: $color_4;
      border-radius: 4px;
      .p-chips-token-icon {
        margin-left: 0.5rem;
      }
    }
    .p-chips-input-token {
      padding: 0.25rem 0;
      input {
        font-size: 1rem;
        color: $color_3;
        padding: 0;
        margin: 0;
      }
    }
  }
}
p-chips.ng-dirty.ng-invalid {
  .p-chips {
    .p-inputtext {
      border-color: $border-color_3;
    }
  }
}
p-chips.p-chips-clearable {
  .p-inputtext {
    padding-right: 1.75rem;
  }
  .p-chips-clear-icon {
    color: $color_5;
    right: 0.75rem;
  }
}
.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}
.p-fluid {
  .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
  }
  .p-inputgroup {
    .p-button {
      width: auto;
    }
    .p-button.p-button-icon-only {
      width: 2.357rem;
    }
  }
  .p-button {
    width: 100%;
  }
  .p-button-icon-only {
    width: 2.357rem;
  }
  .p-buttonset {
    display: flex;
    .p-button {
      flex: 1;
    }
  }
}
.p-colorpicker-panel {
  background: #212529;
  border: 1px solid #212529;
  .p-colorpicker-color-handle {
    border-color: $border-color_6;
  }
  .p-colorpicker-hue-handle {
    border-color: $border-color_6;
  }
}
.p-colorpicker-overlay-panel {
  box-shadow: none;
}
.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $primary;
  }
  .p-dropdown-label {
    background: transparent;
    border: 0 none;
    &:enabled {
      &:focus {
        outline: 0 none;
        box-shadow: none;
      }
    }
  }
  .p-dropdown-label.p-placeholder {
    color: $color_2;
  }
  .p-dropdown-trigger {
    background: transparent;
    color: $gray-400;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .p-dropdown-clear-icon {
    color: $color_5;
    right: 2.357rem;
  }
}
.p-dropdown.p-dropdown-clearable {
  .p-dropdown-label {
    padding-right: 1.75rem;
  }
}
.p-dropdown-panel {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  .p-dropdown-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid $border-color;
    color: $color_3;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-dropdown-filter {
      padding-right: 1.75rem;
      margin-right: -1.75rem;
    }
    .p-dropdown-filter-icon {
      right: 0.75rem;
      color: $color_5;
    }
  }
  .p-dropdown-items {
    &:not(.p-dropdown-virtualscroll) {
      padding: 0.5rem 0;
    }
    .p-dropdown-item {
      margin: 0;
      padding: 0.5rem 1.5rem;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 0;
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: $color_3;
            background: #e9ecef;
          }
        }
      }
    }
    .p-dropdown-item.p-highlight {
      color: $white;
      background: $primary;
    }
    .p-dropdown-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
      background: transparent;
    }
    .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: $color_3;
      background: #ffffff;
      font-weight: 600;
    }
  }
  .p-dropdown-items.p-dropdown-virtualscroll {
    .cdk-virtual-scroll-content-wrapper {
      padding: 0.5rem 0;
    }
  }
}
p-dropdown.ng-dirty.ng-invalid {
  .p-dropdown {
    border-color: $border-color_3;
  }
}
.p-editor-container {
  .p-editor-toolbar {
    background: #efefef;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .p-editor-toolbar.ql-snow {
    border: 1px solid $border-color;
    .ql-stroke {
      stroke: #6c757d;
    }
    .ql-fill {
      fill: #6c757d;
    }
    .ql-picker {
      .ql-picker-label {
        border: 0 none;
        color: $color_2;
        &:hover {
          color: $color_3;
          .ql-stroke {
            stroke: #212529;
          }
          .ql-fill {
            fill: #212529;
          }
        }
      }
    }
    .ql-picker.ql-expanded {
      .ql-picker-label {
        color: $color_3;
        .ql-stroke {
          stroke: #212529;
        }
        .ql-fill {
          fill: #212529;
        }
      }
      .ql-picker-options {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;
        border-radius: 4px;
        padding: 0.5rem 0;
        .ql-picker-item {
          color: $color_3;
          &:hover {
            color: $color_3;
            background: #e9ecef;
          }
        }
      }
      &:not(.ql-icon-picker) {
        .ql-picker-item {
          padding: 0.5rem 1.5rem;
        }
      }
    }
  }
  .p-editor-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .ql-editor {
      background: #ffffff;
      color: $color_5;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  .p-editor-content.ql-snow {
    border: 1px solid $border-color;
  }
  .ql-snow.ql-toolbar {
    button {
      &:hover {
        color: $color_3;
        .ql-stroke {
          stroke: #212529;
        }
        .ql-fill {
          fill: #212529;
        }
      }
      &:focus {
        color: $color_3;
        .ql-stroke {
          stroke: #212529;
        }
        .ql-fill {
          fill: #212529;
        }
      }
    }
    button.ql-active {
      color: $primary;
      .ql-stroke {
        stroke: #007bff;
      }
      .ql-fill {
        fill: #007bff;
      }
      .ql-picker-label {
        color: $primary;
      }
    }
    .ql-picker-label.ql-active {
      color: $primary;
      .ql-stroke {
        stroke: #007bff;
      }
      .ql-fill {
        fill: #007bff;
      }
      .ql-picker-label {
        color: $primary;
      }
    }
    .ql-picker-item.ql-selected {
      color: $primary;
      .ql-stroke {
        stroke: #007bff;
      }
      .ql-fill {
        fill: #007bff;
      }
      .ql-picker-label {
        color: $primary;
      }
    }
  }
}
.p-inputgroup-addon {
  background: #e9ecef;
  color: $color_5;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.75rem;
  min-width: 2.357rem;
  &:last-child {
    border-right: 1px solid #ced4da;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
.p-inputgroup {
  .p-component {
    border-radius: 0;
    margin: 0;
    + {
      .p-inputgroup-addon {
        border-left: 0 none;
      }
    }
    &:focus {
      z-index: 1;
      ~ {
        label {
          z-index: 1;
        }
      }
    }
  }
  .p-element {
    border-radius: 0;
    margin: 0;
    + {
      .p-inputgroup-addon {
        border-left: 0 none;
      }
    }
    &:focus {
      z-index: 1;
      ~ {
        label {
          z-index: 1;
        }
      }
    }
  }
  .p-inputwrapper {
    .p-component {
      .p-inputtext {
        border-radius: 0;
        margin: 0;
        + {
          .p-inputgroup-addon {
            border-left: 0 none;
          }
        }
        &:focus {
          z-index: 1;
          ~ {
            label {
              z-index: 1;
            }
          }
        }
      }
    }
    &:first-child {
      .p-component {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        .p-inputtext {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
    &:last-child {
      .p-component {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .p-inputtext {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  .p-float-label {
    .p-component {
      border-radius: 0;
      margin: 0;
      + {
        .p-inputgroup-addon {
          border-left: 0 none;
        }
      }
      &:focus {
        z-index: 1;
        ~ {
          label {
            z-index: 1;
          }
        }
      }
    }
  }
  button {
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  input {
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .p-float-label {
    &:first-child {
      input {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      input {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
p-inputmask.ng-dirty.ng-invalid {
  .p-inputtext {
    border-color: $border-color_3;
  }
}
p-inputmask.p-inputmask-clearable {
  .p-inputtext {
    padding-right: 2.5rem;
  }
  .p-inputmask-clear-icon {
    color: $color_5;
    right: 0.75rem;
  }
}
p-inputnumber.ng-dirty.ng-invalid {
  .p-inputnumber {
    .p-inputtext {
      border-color: $border-color_3;
    }
  }
}
p-inputnumber.p-inputnumber-clearable {
  .p-inputnumber-input {
    padding-right: 2.5rem;
  }
  .p-inputnumber-clear-icon {
    color: $color_5;
    right: 0.75rem;
  }
  .p-inputnumber-buttons-stacked {
    .p-inputnumber-clear-icon {
      right: 3.107rem;
    }
  }
  .p-inputnumber-buttons-horizontal {
    .p-inputnumber-clear-icon {
      right: 3.107rem;
    }
  }
}
.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
  .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    &:before {
      background: #ffffff;
      width: 1.25rem;
      height: 1.25rem;
      left: 0.25rem;
      margin-top: -0.625rem;
      border-radius: 4px;
      transition-duration: 0.15s;
    }
  }
  &:not(.p-disabled) {
    &:hover {
      .p-inputswitch-slider {
        background: #ced4da;
      }
    }
  }
}
.p-inputswitch.p-inputswitch-checked {
  .p-inputswitch-slider {
    &:before {
      transform: translateX(1.25rem);
      background: #ffffff;
    }
    background: #007bff;
  }
  &:not(.p-disabled) {
    &:hover {
      .p-inputswitch-slider {
        background: #007bff;
      }
    }
  }
}
.p-inputswitch.p-focus {
  .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;

  }
}
p-inputswitch.ng-dirty.ng-invalid {
  .p-inputswitch {
    border-color: $border-color_3;
  }
}
.p-inputtext {
  font-size: 1rem;
  color: $color_5;
  background: #ffffff;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  appearance: none;
  border-radius: 4px;
  &:enabled {
    &:hover {
      border-color: $border-color_1;
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;

      border-color: $border-color_2;
    }
  }
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: $border-color_3;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}
.p-float-label {
  label {
    left: 0.75rem;
    color: $color_2;
    transition-duration: 0.15s;
  }
  .ng-invalid.ng-dirty {
    + {
      label {
        color: $color_1;
      }
    }
  }
}
.p-input-icon-left {
  i {
    &:first-of-type {
      left: 0.75rem;
      color: $color_5;
    }
  }
  .p-inputtext {
    padding-left: 2.5rem;
  }
}
.p-input-icon-left.p-float-label {
  label {
    left: 2.5rem;
  }
}
.p-input-icon-right {
  i {
    &:last-of-type {
      right: 0.75rem;
      color: $color_5;
    }
  }
  .p-inputtext {
    padding-right: 2.5rem;
  }
}
.p-inputtext-sm {
  .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
  }
}
.p-inputtext-lg {
  .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
  }
}
.p-listbox {
  background: #ffffff;
  color: $color_3;
  border: 1px solid #ced4da;
  border-radius: 4px;
  .p-listbox-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid $border-color;
    color: $color_3;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-listbox-filter {
      padding-right: 1.75rem;
    }
    .p-listbox-filter-icon {
      right: 0.75rem;
      color: $color_5;
    }
    .p-checkbox {
      margin-right: 0.5rem;
    }
  }
  .p-listbox-list {
    padding: 0.5rem 0;
    .p-listbox-item {
      margin: 0;
      padding: 0.5rem 1.5rem;
      border: 0 none;
      color: $color_3;
      transition: box-shadow 0.15s;
      border-radius: 0;
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(#c0262d, 0.5);
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
    }
    .p-listbox-item.p-highlight {
      color: $color_4;
      background: rgba(#c0262d, 0.2);
    }
    .p-listbox-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: $color_3;
      background: #ffffff;
      font-weight: 600;
    }
    .p-listbox-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
      background: transparent;
    }
  }
  &:not(.p-disabled) {
    .p-listbox-item {
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: $color_3;
            background: #e9ecef;
          }
        }
      }
    }
  }
}
p-listbox.ng-dirty.ng-invalid {
  .p-listbox {
    border-color: $border-color_3;
  }
}
.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $border-color_2;
  }
  .p-multiselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  }
  .p-multiselect-label.p-placeholder {
    color: $color_2;
  }
  .p-multiselect-trigger {
    background: transparent;
    color: $color_5;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.p-multiselect.p-multiselect-chip {
  .p-multiselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: #007bff;
    color: $color_4;
    border-radius: 4px;
    .p-multiselect-token-icon {
      margin-left: 0.5rem;
    }
  }
}
.p-inputwrapper-filled {
  .p-multiselect.p-multiselect-chip {
    .p-multiselect-label {
      padding: 0.25rem 0.75rem;
    }
  }
  .p-treeselect.p-treeselect-chip {
    .p-treeselect-label {
      padding: 0.25rem 0.75rem;
    }
  }
}
.p-multiselect-panel {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  .p-multiselect-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid $border-color;
    color: $color_3;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-multiselect-filter-container {
      .p-inputtext {
        padding-right: 1.75rem;
      }
      .p-multiselect-filter-icon {
        right: 0.75rem;
        color: $color_5;
      }
    }
    .p-checkbox {
      margin-right: 0.5rem;
    }
    .p-multiselect-close {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
  .p-multiselect-items {
    &:not(.p-multiselect-virtualscroll) {
      padding: 0.5rem 0;
    }
    .p-multiselect-item {
      margin: 0;
      padding: 0.5rem 1.5rem;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 0;
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: $color_3;
            background: #e9ecef;
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
    }
    .p-multiselect-item.p-highlight {
      color: $color_4;
      background: #007bff;
    }
    .p-multiselect-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: $color_3;
      background: #ffffff;
      font-weight: 600;
    }
    .p-multiselect-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
      background: transparent;
    }
  }
  .p-multiselect-items.p-multiselect-virtualscroll {
    .cdk-virtual-scroll-content-wrapper {
      padding: 0.5rem 0;
    }
  }
}
p-multiselect.ng-dirty.ng-invalid {
  .p-multiselect {
    border-color: $border-color_3;
  }
}
p-multiselect.p-multiselect-clearable {
  .p-multiselect-label-container {
    padding-right: 1.75rem;
  }
  .p-multiselect-clear-icon {
    color: $color_5;
    right: 2.357rem;
  }
}
p-password.ng-invalid.ng-dirty {
  .p-password {
    .p-inputtext {
      border-color: $border-color_3;
    }
  }
}
.p-password-panel {
  padding: 1.25rem;
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 4px;
  .p-password-meter {
    margin-bottom: 0.5rem;
    background: #e9ecef;
    .p-password-strength.weak {
      background: #dc3545;
    }
    .p-password-strength.medium {
      background: #ffc107;
    }
    .p-password-strength.strong {
      background: #28a745;
    }
  }
}
p-password.p-password-clearable {
  .p-password-input {
    padding-right: 2.5rem;
  }
  .p-password-clear-icon {
    color: $color_5;
    right: 0.75rem;
  }
}
p-password.p-password-clearable.p-password-mask {
  .p-password-input {
    padding-right: 4.25rem;
  }
  .p-password-clear-icon {
    color: $color_5;
    right: 2.5rem;
  }
}
.p-radiobutton {
  width: 20px;
  height: 20px;
  .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: $color_3;
    border-radius: 50%;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          border-color: $border-color_1;
        }
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;

      border-color: $border-color_2;
    }
    .p-radiobutton-icon {
      width: 12px;
      height: 12px;
      transition-duration: 0.15s;
      background-color: $background-color_4;
    }
  }
  .p-radiobutton-box.p-highlight {
    border-color: $border-color_2;
    background: #007bff;
    &:not(.p-disabled) {
      &:hover {
        border-color: $border-color_5;
        background: #0062cc;
        color: $color_4;
      }
    }
  }
}
p-radiobutton.ng-dirty.ng-invalid {
  .p-radiobutton {
    .p-radiobutton-box {
      border-color: $border-color_3;
    }
  }
}
.p-radiobutton-label {
  margin-left: 0.5rem;
}
.p-rating {
  .p-rating-icon {
    color: $color_5;
    margin-left: 0.5rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    font-size: 1.143rem;
    &:focus {
      outline: 0 none;
      outline-offset: 0;

    }
    &:first-child {
      margin-left: 0;
    }
  }
  .p-rating-icon.p-rating-cancel {
    color: $color_1;
  }
  .p-rating-icon.pi-star-fill {
    color: $primary;
  }
  &:not(.p-disabled) {
    &:not(.p-readonly) {
      .p-rating-icon {
        &:hover {
          color: $primary;
        }
      }
      .p-rating-icon.p-rating-cancel {
        &:hover {
          color: $color_1;
        }
      }
    }
  }
}
.p-selectbutton {
  .p-button {
    background: #6c757d;
    border: 1px solid #6c757d;
    color: $color_4;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    .p-button-icon-left {
      color: $color_4;
    }
    .p-button-icon-right {
      color: $color_4;
    }
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: #5a6268;
          border-color: $border-color_7;
          color: $color_4;
          .p-button-icon-left {
            color: $color_4;
          }
          .p-button-icon-right {
            color: $color_4;
          }
        }
      }
    }
  }
  .p-button.p-highlight {
    background: #545b62;
    border-color: $border-color_8;
    color: $color_4;
    .p-button-icon-left {
      color: $color_4;
    }
    .p-button-icon-right {
      color: $color_4;
    }
    &:hover {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      .p-button-icon-left {
        color: $color_4;
      }
      .p-button-icon-right {
        color: $color_4;
      }
    }
  }
}
p-selectbutton.ng-dirty.ng-invalid {
  .p-selectbutton {
    .p-button {
      border-color: $border-color_3;
    }
  }
}
.p-slider {
  background: #e9ecef;
  border: 0 none;
  border-radius: 4px;
  .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #007bff;
    border: 2px solid #007bff;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    &:focus {
      outline: 0 none;
      outline-offset: 0;

    }
  }
  .p-slider-range {
    background: #007bff;
  }
  &:not(.p-disabled) {
    .p-slider-handle {
      &:hover {
        background: #0069d9;
        border-color: $border-color_9;
      }
    }
  }
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
  .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
  }
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
  .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
  }
}
.p-slider.p-slider-animate.p-slider-horizontal {
  .p-slider-handle {
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, left 0.15s;
  }
  .p-slider-range {
    transition: width 0.15s;
  }
}
.p-slider.p-slider-animate.p-slider-vertical {
  .p-slider-handle {
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, bottom 0.15s;
  }
  .p-slider-range {
    transition: height 0.15s;
  }
}
.p-togglebutton.p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: $color_4;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  .p-button-icon-left {
    color: $color_4;
  }
  .p-button-icon-right {
    color: $color_4;
  }
  &:not(.p-disabled) {
    &:not(.p-highlight) {
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
        .p-button-icon-left {
          color: $color_4;
        }
        .p-button-icon-right {
          color: $color_4;
        }
      }
    }
  }
}
.p-togglebutton.p-button.p-highlight {
  background: #545b62;
  border-color: $border-color_8;
  color: $color_4;
  .p-button-icon-left {
    color: $color_4;
  }
  .p-button-icon-right {
    color: $color_4;
  }
  &:hover {
    background: #545b62;
    border-color: $border-color_8;
    color: $color_4;
    .p-button-icon-left {
      color: $color_4;
    }
    .p-button-icon-right {
      color: $color_4;
    }
  }
}
p-togglebutton.ng-dirty.ng-invalid {
  .p-togglebutton.p-button {
    border-color: $border-color_3;
  }
}
.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  &:not(.p-disabled) {
    &:hover {
      border-color: $border-color_1;
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;

    border-color: $border-color_2;
  }
  .p-treeselect-label {
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  }
  .p-treeselect-label.p-placeholder {
    color: $color_2;
  }
  .p-treeselect-trigger {
    background: transparent;
    color: $color_5;
    width: 2.357rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.p-treeselect.p-treeselect-chip {
  .p-treeselect-token {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    background: $border-color;
    color: $color_3;
    border-radius: 16px;
  }
}
p-treeselect.ng-invalid.ng-dirty {
  .p-treeselect {
    border-color: $border-color_3;
  }
}
.p-treeselect-panel {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  .p-treeselect-header {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid $border-color;
    color: $color_3;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-treeselect-filter-container {
      margin-right: 0.5rem;
      .p-treeselect-filter {
        padding-right: 1.75rem;
      }
      .p-treeselect-filter-icon {
        right: 0.75rem;
        color: $color_5;
      }
    }
    .p-treeselect-filter-container.p-treeselect-clearable-filter {
      .p-treeselect-filter {
        padding-right: 3.5rem;
      }
      .p-treeselect-filter-clear-icon {
        right: 2.5rem;
      }
    }
    .p-treeselect-close {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
  .p-treeselect-items-wrapper {
    .p-tree {
      border: 0 none;
    }
    .p-treeselect-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
      background: transparent;
    }
  }
}
p-treeselect.p-treeselect-clearable {
  .p-treeselect-label-container {
    padding-right: 1.75rem;
  }
  .p-treeselect-clear-icon {
    color: $color_5;
    right: 2.357rem;
  }
}
.p-button {
  color: $color_4;
  background: #007bff;
  border: 1px solid #007bff;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  &:enabled {
    &:hover {
      background: #0069d9;
      color: $color_4;
      border-color: $border-color_9;
    }
    &:active {
      background: #0062cc;
      color: $color_4;
      border-color: $border-color_5;
    }
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;

  }
  .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: $primary;
    background-color: $background-color_4;
  }
}
.p-button.p-button-outlined {
  background-color: $background-color_3;
  color: $primary;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(0, 123, 255, 0.04);
      color: $primary;
      border: 1px solid;
    }
    &:active {
      background: rgba(0, 123, 255, 0.16);
      color: $primary;
      border: 1px solid;
    }
  }
}
.p-button.p-button-outlined.p-button-plain {
  color: $color_2;
  border-color: $border-color_10;
  &:enabled {
    &:hover {
      background: #e9ecef;
      color: $color_2;
    }
    &:active {
      background: $border-color;
      color: $color_2;
    }
  }
}
.p-button.p-button-text {
  background-color: $background-color_3;
  color: $primary;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(0, 123, 255, 0.04);
      color: $primary;
      border-color: $border-color_4;
    }
    &:active {
      background: rgba(0, 123, 255, 0.16);
      color: $primary;
      border-color: $border-color_4;
    }
  }
}
.p-button.p-button-text.p-button-plain {
  color: $color_2;
  &:enabled {
    &:hover {
      background: #e9ecef;
      color: $color_2;
    }
    &:active {
      background: $border-color;
      color: $color_2;
    }
  }
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
  .p-button-icon-left {
    margin: 0;
  }
  .p-button-icon-right {
    margin: 0;
  }
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
  .p-button-icon {
    font-size: 0.875rem;
  }
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
  .p-button-icon {
    font-size: 1.25rem;
  }
}
.p-button.p-button-loading-label-only {
  .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button-loading-icon {
    margin-right: 0;
  }
}
.p-button.p-button-secondary {
  color: $color_4;
  background: #6c757d;
  border: 1px solid #6c757d;
  &:enabled {
    &:hover {
      background: #5a6268;
      color: $color_4;
      border-color: $border-color_11;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
    }
    &:active {
      background: #545b62;
      color: $color_4;
      border-color: $border-color_8;
    }
  }
}
.p-buttonset.p-button-secondary {
  .p-button {
    color: $color_4;
    background: #6c757d;
    border: 1px solid #6c757d;
    &:enabled {
      &:hover {
        background: #5a6268;
        color: $color_4;
        border-color: $border-color_11;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
      }
      &:active {
        background: #545b62;
        color: $color_4;
        border-color: $border-color_8;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_2;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(108, 117, 125, 0.04);
        color: $color_2;
        border: 1px solid;
      }
      &:active {
        background: rgba(108, 117, 125, 0.16);
        color: $color_2;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_2;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(108, 117, 125, 0.04);
        border-color: $border-color_4;
        color: $color_2;
      }
      &:active {
        background: rgba(108, 117, 125, 0.16);
        border-color: $border-color_4;
        color: $color_2;
      }
    }
  }
}
.p-splitbutton.p-button-secondary {
  .p-button {
    color: $color_4;
    background: #6c757d;
    border: 1px solid #6c757d;
    &:enabled {
      &:hover {
        background: #5a6268;
        color: $color_4;
        border-color: $border-color_11;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
      }
      &:active {
        background: #545b62;
        color: $color_4;
        border-color: $border-color_8;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_2;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(108, 117, 125, 0.04);
        color: $color_2;
        border: 1px solid;
      }
      &:active {
        background: rgba(108, 117, 125, 0.16);
        color: $color_2;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_2;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(108, 117, 125, 0.04);
        border-color: $border-color_4;
        color: $color_2;
      }
      &:active {
        background: rgba(108, 117, 125, 0.16);
        border-color: $border-color_4;
        color: $color_2;
      }
    }
  }
}
.p-button.p-button-secondary.p-button-outlined {
  background-color: $background-color_3;
  color: $color_2;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(108, 117, 125, 0.04);
      color: $color_2;
      border: 1px solid;
    }
    &:active {
      background: rgba(108, 117, 125, 0.16);
      color: $color_2;
      border: 1px solid;
    }
  }
}
.p-button.p-button-secondary.p-button-text {
  background-color: $background-color_3;
  color: $color_2;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(108, 117, 125, 0.04);
      border-color: $border-color_4;
      color: $color_2;
    }
    &:active {
      background: rgba(108, 117, 125, 0.16);
      border-color: $border-color_4;
      color: $color_2;
    }
  }
}
.p-button.p-button-info {
  color: $color_4;
  background: #17a2b8;
  border: 1px solid #17a2b8;
  &:enabled {
    &:hover {
      background: #138496;
      color: $color_4;
      border-color: $border-color_12;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    }
    &:active {
      background: #138496;
      color: $color_4;
      border-color: $border-color_12;
    }
  }
}
.p-buttonset.p-button-info {
  .p-button {
    color: $color_4;
    background: #17a2b8;
    border: 1px solid #17a2b8;
    &:enabled {
      &:hover {
        background: #138496;
        color: $color_4;
        border-color: $border-color_12;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      }
      &:active {
        background: #138496;
        color: $color_4;
        border-color: $border-color_12;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_7;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(23, 162, 184, 0.04);
        color: $color_7;
        border: 1px solid;
      }
      &:active {
        background: rgba(23, 162, 184, 0.16);
        color: $color_7;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_7;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(23, 162, 184, 0.04);
        border-color: $border-color_4;
        color: $color_7;
      }
      &:active {
        background: rgba(23, 162, 184, 0.16);
        border-color: $border-color_4;
        color: $color_7;
      }
    }
  }
}
.p-splitbutton.p-button-info {
  .p-button {
    color: $color_4;
    background: #17a2b8;
    border: 1px solid #17a2b8;
    &:enabled {
      &:hover {
        background: #138496;
        color: $color_4;
        border-color: $border-color_12;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      }
      &:active {
        background: #138496;
        color: $color_4;
        border-color: $border-color_12;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_7;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(23, 162, 184, 0.04);
        color: $color_7;
        border: 1px solid;
      }
      &:active {
        background: rgba(23, 162, 184, 0.16);
        color: $color_7;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_7;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(23, 162, 184, 0.04);
        border-color: $border-color_4;
        color: $color_7;
      }
      &:active {
        background: rgba(23, 162, 184, 0.16);
        border-color: $border-color_4;
        color: $color_7;
      }
    }
  }
}
.p-button.p-button-info.p-button-outlined {
  background-color: $background-color_3;
  color: $color_7;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(23, 162, 184, 0.04);
      color: $color_7;
      border: 1px solid;
    }
    &:active {
      background: rgba(23, 162, 184, 0.16);
      color: $color_7;
      border: 1px solid;
    }
  }
}
.p-button.p-button-info.p-button-text {
  background-color: $background-color_3;
  color: $color_7;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(23, 162, 184, 0.04);
      border-color: $border-color_4;
      color: $color_7;
    }
    &:active {
      background: rgba(23, 162, 184, 0.16);
      border-color: $border-color_4;
      color: $color_7;
    }
  }
}
.p-button.p-button-success {
  color: $color_4;
  background: #28a745;
  border: 1px solid #28a745;
  &:enabled {
    &:hover {
      background: #218838;
      color: $color_4;
      border-color: $border-color_13;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
    }
    &:active {
      background: #1e7e34;
      color: $color_4;
      border-color: $border-color_14;
    }
  }
}
.p-buttonset.p-button-success {
  .p-button {
    color: $color_4;
    background: #28a745;
    border: 1px solid #28a745;
    &:enabled {
      &:hover {
        background: #218838;
        color: $color_4;
        border-color: $border-color_13;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
      }
      &:active {
        background: #1e7e34;
        color: $color_4;
        border-color: $border-color_14;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_8;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(40, 167, 69, 0.04);
        color: $color_8;
        border: 1px solid;
      }
      &:active {
        background: rgba(40, 167, 69, 0.16);
        color: $color_8;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_8;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(40, 167, 69, 0.04);
        border-color: $border-color_4;
        color: $color_8;
      }
      &:active {
        background: rgba(40, 167, 69, 0.16);
        border-color: $border-color_4;
        color: $color_8;
      }
    }
  }
}
.p-splitbutton.p-button-success {
  .p-button {
    color: $color_4;
    background: #28a745;
    border: 1px solid #28a745;
    &:enabled {
      &:hover {
        background: #218838;
        color: $color_4;
        border-color: $border-color_13;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
      }
      &:active {
        background: #1e7e34;
        color: $color_4;
        border-color: $border-color_14;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_8;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(40, 167, 69, 0.04);
        color: $color_8;
        border: 1px solid;
      }
      &:active {
        background: rgba(40, 167, 69, 0.16);
        color: $color_8;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_8;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(40, 167, 69, 0.04);
        border-color: $border-color_4;
        color: $color_8;
      }
      &:active {
        background: rgba(40, 167, 69, 0.16);
        border-color: $border-color_4;
        color: $color_8;
      }
    }
  }
}
.p-button.p-button-success.p-button-outlined {
  background-color: $background-color_3;
  color: $color_8;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(40, 167, 69, 0.04);
      color: $color_8;
      border: 1px solid;
    }
    &:active {
      background: rgba(40, 167, 69, 0.16);
      color: $color_8;
      border: 1px solid;
    }
  }
}
.p-button.p-button-success.p-button-text {
  background-color: $background-color_3;
  color: $color_8;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(40, 167, 69, 0.04);
      border-color: $border-color_4;
      color: $color_8;
    }
    &:active {
      background: rgba(40, 167, 69, 0.16);
      border-color: $border-color_4;
      color: $color_8;
    }
  }
}
.p-button.p-button-warning {
  color: $color_3;
  background: #ffc107;
  border: 1px solid #ffc107;
  &:enabled {
    &:hover {
      background: #e0a800;
      color: $color_3;
      border-color: $border-color_15;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
    }
    &:active {
      background: #d39e00;
      color: $color_3;
      border-color: $border-color_16;
    }
  }
}
.p-buttonset.p-button-warning {
  .p-button {
    color: $color_3;
    background: #ffc107;
    border: 1px solid #ffc107;
    &:enabled {
      &:hover {
        background: #e0a800;
        color: $color_3;
        border-color: $border-color_15;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
      }
      &:active {
        background: #d39e00;
        color: $color_3;
        border-color: $border-color_16;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_9;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(255, 193, 7, 0.04);
        color: $color_9;
        border: 1px solid;
      }
      &:active {
        background: rgba(255, 193, 7, 0.16);
        color: $color_9;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_9;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(255, 193, 7, 0.04);
        border-color: $border-color_4;
        color: $color_9;
      }
      &:active {
        background: rgba(255, 193, 7, 0.16);
        border-color: $border-color_4;
        color: $color_9;
      }
    }
  }
}
.p-splitbutton.p-button-warning {
  .p-button {
    color: $color_3;
    background: #ffc107;
    border: 1px solid #ffc107;
    &:enabled {
      &:hover {
        background: #e0a800;
        color: $color_3;
        border-color: $border-color_15;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
      }
      &:active {
        background: #d39e00;
        color: $color_3;
        border-color: $border-color_16;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_9;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(255, 193, 7, 0.04);
        color: $color_9;
        border: 1px solid;
      }
      &:active {
        background: rgba(255, 193, 7, 0.16);
        color: $color_9;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_9;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(255, 193, 7, 0.04);
        border-color: $border-color_4;
        color: $color_9;
      }
      &:active {
        background: rgba(255, 193, 7, 0.16);
        border-color: $border-color_4;
        color: $color_9;
      }
    }
  }
}
.p-button.p-button-warning.p-button-outlined {
  background-color: $background-color_3;
  color: $color_9;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(255, 193, 7, 0.04);
      color: $color_9;
      border: 1px solid;
    }
    &:active {
      background: rgba(255, 193, 7, 0.16);
      color: $color_9;
      border: 1px solid;
    }
  }
}
.p-button.p-button-warning.p-button-text {
  background-color: $background-color_3;
  color: $color_9;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(255, 193, 7, 0.04);
      border-color: $border-color_4;
      color: $color_9;
    }
    &:active {
      background: rgba(255, 193, 7, 0.16);
      border-color: $border-color_4;
      color: $color_9;
    }
  }
}
.p-button.p-button-help {
  color: $color_4;
  background: #6f42c1;
  border: 1px solid #6f42c1;
  &:enabled {
    &:hover {
      background: #633bad;
      color: $color_4;
      border-color: $border-color_17;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem #d3c6ec;
    }
    &:active {
      background: #58349a;
      color: $color_4;
      border-color: $border-color_18;
    }
  }
}
.p-buttonset.p-button-help {
  .p-button {
    color: $color_4;
    background: #6f42c1;
    border: 1px solid #6f42c1;
    &:enabled {
      &:hover {
        background: #633bad;
        color: $color_4;
        border-color: $border-color_17;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem #d3c6ec;
      }
      &:active {
        background: #58349a;
        color: $color_4;
        border-color: $border-color_18;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_10;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(111, 66, 193, 0.04);
        color: $color_10;
        border: 1px solid;
      }
      &:active {
        background: rgba(111, 66, 193, 0.16);
        color: $color_10;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_10;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(111, 66, 193, 0.04);
        border-color: $border-color_4;
        color: $color_10;
      }
      &:active {
        background: rgba(111, 66, 193, 0.16);
        border-color: $border-color_4;
        color: $color_10;
      }
    }
  }
}
.p-splitbutton.p-button-help {
  .p-button {
    color: $color_4;
    background: #6f42c1;
    border: 1px solid #6f42c1;
    &:enabled {
      &:hover {
        background: #633bad;
        color: $color_4;
        border-color: $border-color_17;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem #d3c6ec;
      }
      &:active {
        background: #58349a;
        color: $color_4;
        border-color: $border-color_18;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_10;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(111, 66, 193, 0.04);
        color: $color_10;
        border: 1px solid;
      }
      &:active {
        background: rgba(111, 66, 193, 0.16);
        color: $color_10;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_10;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(111, 66, 193, 0.04);
        border-color: $border-color_4;
        color: $color_10;
      }
      &:active {
        background: rgba(111, 66, 193, 0.16);
        border-color: $border-color_4;
        color: $color_10;
      }
    }
  }
}
.p-button.p-button-help.p-button-outlined {
  background-color: $background-color_3;
  color: $color_10;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(111, 66, 193, 0.04);
      color: $color_10;
      border: 1px solid;
    }
    &:active {
      background: rgba(111, 66, 193, 0.16);
      color: $color_10;
      border: 1px solid;
    }
  }
}
.p-button.p-button-help.p-button-text {
  background-color: $background-color_3;
  color: $color_10;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(111, 66, 193, 0.04);
      border-color: $border-color_4;
      color: $color_10;
    }
    &:active {
      background: rgba(111, 66, 193, 0.16);
      border-color: $border-color_4;
      color: $color_10;
    }
  }
}
.p-button.p-button-danger {
  color: $color_4;
  background: #dc3545;
  border: 1px solid #dc3545;
  &:enabled {
    &:hover {
      background: #c82333;
      color: $color_4;
      border-color: $border-color_19;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }
    &:active {
      background: #bd2130;
      color: $color_4;
      border-color: $border-color_20;
    }
  }
}
.p-buttonset.p-button-danger {
  .p-button {
    color: $color_4;
    background: #dc3545;
    border: 1px solid #dc3545;
    &:enabled {
      &:hover {
        background: #c82333;
        color: $color_4;
        border-color: $border-color_19;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      }
      &:active {
        background: #bd2130;
        color: $color_4;
        border-color: $border-color_20;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_1;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(220, 53, 69, 0.04);
        color: $color_1;
        border: 1px solid;
      }
      &:active {
        background: rgba(220, 53, 69, 0.16);
        color: $color_1;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_1;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(220, 53, 69, 0.04);
        border-color: $border-color_4;
        color: $color_1;
      }
      &:active {
        background: rgba(220, 53, 69, 0.16);
        border-color: $border-color_4;
        color: $color_1;
      }
    }
  }
}
.p-splitbutton.p-button-danger {
  .p-button {
    color: $color_4;
    background: #dc3545;
    border: 1px solid #dc3545;
    &:enabled {
      &:hover {
        background: #c82333;
        color: $color_4;
        border-color: $border-color_19;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      }
      &:active {
        background: #bd2130;
        color: $color_4;
        border-color: $border-color_20;
      }
    }
  }
  .p-button.p-button-outlined {
    background-color: $background-color_3;
    color: $color_1;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(220, 53, 69, 0.04);
        color: $color_1;
        border: 1px solid;
      }
      &:active {
        background: rgba(220, 53, 69, 0.16);
        color: $color_1;
        border: 1px solid;
      }
    }
  }
  .p-button.p-button-text {
    background-color: $background-color_3;
    color: $color_1;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(220, 53, 69, 0.04);
        border-color: $border-color_4;
        color: $color_1;
      }
      &:active {
        background: rgba(220, 53, 69, 0.16);
        border-color: $border-color_4;
        color: $color_1;
      }
    }
  }
}
.p-button.p-button-danger.p-button-outlined {
  background-color: $background-color_3;
  color: $color_1;
  border: 1px solid;
  &:enabled {
    &:hover {
      background: rgba(220, 53, 69, 0.04);
      color: $color_1;
      border: 1px solid;
    }
    &:active {
      background: rgba(220, 53, 69, 0.16);
      color: $color_1;
      border: 1px solid;
    }
  }
}
.p-button.p-button-danger.p-button-text {
  background-color: $background-color_3;
  color: $color_1;
  border-color: $border-color_4;
  &:enabled {
    &:hover {
      background: rgba(220, 53, 69, 0.04);
      border-color: $border-color_4;
      color: $color_1;
    }
    &:active {
      background: rgba(220, 53, 69, 0.16);
      border-color: $border-color_4;
      color: $color_1;
    }
  }
}
.p-button.p-button-link {
  color: $primary;
  background: transparent;
  border: transparent;
  &:enabled {
    &:hover {
      background: transparent;
      color: $color_11;
      border-color: $border-color_4;
      .p-button-label {
        text-decoration: underline;
      }
    }
    &:focus {
      background: transparent;

      border-color: $border-color_4;
    }
    &:active {
      background: transparent;
      color: $primary;
      border-color: $border-color_4;
    }
  }
}
.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
  .p-button-icon {
    font-size: 1.3rem;
  }
}
.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: $color_12;
  &:hover {
    background: #343a40;
    color: $color_12;
  }
}
.p-speeddial-direction-up {
  .p-speeddial-item {
    margin: 0.25rem 0;
    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
}
.p-speeddial-direction-down {
  .p-speeddial-item {
    margin: 0.25rem 0;
    &:first-child {
      margin-top: 0.5rem;
    }
  }
}
.p-speeddial-direction-left {
  .p-speeddial-item {
    margin: 0 0.25rem;
    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
.p-speeddial-direction-right {
  .p-speeddial-item {
    margin: 0 0.25rem;
    &:first-child {
      margin-left: 0.5rem;
    }
  }
}
.p-speeddial-circle {
  .p-speeddial-item {
    margin: 0;
    &:first-child {
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.p-speeddial-semi-circle {
  .p-speeddial-item {
    margin: 0;
    &:first-child {
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.p-speeddial-quarter-circle {
  .p-speeddial-item {
    margin: 0;
    &:first-child {
      margin: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.p-speeddial-mask {
  background-color: $background-color_1;
}
.p-splitbutton {
  border-radius: 4px;
}
.p-splitbutton.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $primary;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(0, 123, 255, 0.04);
        color: $primary;
      }
      &:active {
        background: rgba(0, 123, 255, 0.16);
        color: $primary;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 123, 255, 0.04);
            color: $primary;
          }
          &:active {
            background: rgba(0, 123, 255, 0.16);
            color: $primary;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-outlined.p-button-plain {
  .p-button {
    color: $color_2;
    border-color: $border-color_10;
    &:enabled {
      &:hover {
        background: #e9ecef;
        color: $color_2;
      }
      &:active {
        background: $border-color;
        color: $color_2;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: #e9ecef;
            color: $color_2;
          }
          &:active {
            background: $border-color;
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $primary;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(0, 123, 255, 0.04);
        color: $primary;
        border-color: $border-color_4;
      }
      &:active {
        background: rgba(0, 123, 255, 0.16);
        color: $primary;
        border-color: $border-color_4;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(0, 123, 255, 0.04);
            color: $primary;
            border-color: $border-color_4;
          }
          &:active {
            background: rgba(0, 123, 255, 0.16);
            color: $primary;
            border-color: $border-color_4;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-text.p-button-plain {
  .p-button {
    color: $color_2;
    &:enabled {
      &:hover {
        background: #e9ecef;
        color: $color_2;
      }
      &:active {
        background: $border-color;
        color: $color_2;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: #e9ecef;
            color: $color_2;
          }
          &:active {
            background: $border-color;
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
  .p-button {
    border-radius: 2rem;
  }
}
.p-splitbutton.p-button-sm {
  .p-button {
    font-size: 0.875rem;
    padding: 0.4375rem 0.65625rem;
    .p-button-icon {
      font-size: 0.875rem;
    }
  }
}
.p-splitbutton.p-button-lg {
  .p-button {
    font-size: 1.25rem;
    padding: 0.625rem 0.9375rem;
    .p-button-icon {
      font-size: 1.25rem;
    }
  }
}
.p-splitbutton.p-button-secondary.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $color_2;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(108, 117, 125, 0.04);
        color: $color_2;
      }
      &:active {
        background: rgba(108, 117, 125, 0.16);
        color: $color_2;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(108, 117, 125, 0.04);
            color: $color_2;
          }
          &:active {
            background: rgba(108, 117, 125, 0.16);
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-secondary.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $color_2;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(108, 117, 125, 0.04);
        border-color: $border-color_4;
        color: $color_2;
      }
      &:active {
        background: rgba(108, 117, 125, 0.16);
        border-color: $border-color_4;
        color: $color_2;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(108, 117, 125, 0.04);
            border-color: $border-color_4;
            color: $color_2;
          }
          &:active {
            background: rgba(108, 117, 125, 0.16);
            border-color: $border-color_4;
            color: $color_2;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-info.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $color_7;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(23, 162, 184, 0.04);
        color: $color_7;
      }
      &:active {
        background: rgba(23, 162, 184, 0.16);
        color: $color_7;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(23, 162, 184, 0.04);
            color: $color_7;
          }
          &:active {
            background: rgba(23, 162, 184, 0.16);
            color: $color_7;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-info.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $color_7;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(23, 162, 184, 0.04);
        border-color: $border-color_4;
        color: $color_7;
      }
      &:active {
        background: rgba(23, 162, 184, 0.16);
        border-color: $border-color_4;
        color: $color_7;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(23, 162, 184, 0.04);
            border-color: $border-color_4;
            color: $color_7;
          }
          &:active {
            background: rgba(23, 162, 184, 0.16);
            border-color: $border-color_4;
            color: $color_7;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-success.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $color_8;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(40, 167, 69, 0.04);
        color: $color_8;
      }
      &:active {
        background: rgba(40, 167, 69, 0.16);
        color: $color_8;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(40, 167, 69, 0.04);
            color: $color_8;
          }
          &:active {
            background: rgba(40, 167, 69, 0.16);
            color: $color_8;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-success.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $color_8;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(40, 167, 69, 0.04);
        border-color: $border-color_4;
        color: $color_8;
      }
      &:active {
        background: rgba(40, 167, 69, 0.16);
        border-color: $border-color_4;
        color: $color_8;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(40, 167, 69, 0.04);
            border-color: $border-color_4;
            color: $color_8;
          }
          &:active {
            background: rgba(40, 167, 69, 0.16);
            border-color: $border-color_4;
            color: $color_8;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-warning.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $color_9;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(255, 193, 7, 0.04);
        color: $color_9;
      }
      &:active {
        background: rgba(255, 193, 7, 0.16);
        color: $color_9;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(255, 193, 7, 0.04);
            color: $color_9;
          }
          &:active {
            background: rgba(255, 193, 7, 0.16);
            color: $color_9;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-warning.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $color_9;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(255, 193, 7, 0.04);
        border-color: $border-color_4;
        color: $color_9;
      }
      &:active {
        background: rgba(255, 193, 7, 0.16);
        border-color: $border-color_4;
        color: $color_9;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(255, 193, 7, 0.04);
            border-color: $border-color_4;
            color: $color_9;
          }
          &:active {
            background: rgba(255, 193, 7, 0.16);
            border-color: $border-color_4;
            color: $color_9;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-help.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $color_10;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(111, 66, 193, 0.04);
        color: $color_10;
      }
      &:active {
        background: rgba(111, 66, 193, 0.16);
        color: $color_10;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(111, 66, 193, 0.04);
            color: $color_10;
          }
          &:active {
            background: rgba(111, 66, 193, 0.16);
            color: $color_10;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-help.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $color_10;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(111, 66, 193, 0.04);
        border-color: $border-color_4;
        color: $color_10;
      }
      &:active {
        background: rgba(111, 66, 193, 0.16);
        border-color: $border-color_4;
        color: $color_10;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(111, 66, 193, 0.04);
            border-color: $border-color_4;
            color: $color_10;
          }
          &:active {
            background: rgba(111, 66, 193, 0.16);
            border-color: $border-color_4;
            color: $color_10;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-danger.p-button-outlined {
  .p-button {
    background-color: $background-color_3;
    color: $color_1;
    border: 1px solid;
    &:enabled {
      &:hover {
        background: rgba(220, 53, 69, 0.04);
        color: $color_1;
      }
      &:active {
        background: rgba(220, 53, 69, 0.16);
        color: $color_1;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(220, 53, 69, 0.04);
            color: $color_1;
          }
          &:active {
            background: rgba(220, 53, 69, 0.16);
            color: $color_1;
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-danger.p-button-text {
  .p-button {
    background-color: $background-color_3;
    color: $color_1;
    border-color: $border-color_4;
    &:enabled {
      &:hover {
        background: rgba(220, 53, 69, 0.04);
        border-color: $border-color_4;
        color: $color_1;
      }
      &:active {
        background: rgba(220, 53, 69, 0.16);
        border-color: $border-color_4;
        color: $color_1;
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: rgba(220, 53, 69, 0.04);
            border-color: $border-color_4;
            color: $color_1;
          }
          &:active {
            background: rgba(220, 53, 69, 0.16);
            border-color: $border-color_4;
            color: $color_1;
          }
        }
      }
    }
  }
}
.p-carousel {
  .p-carousel-content {
    .p-carousel-prev {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      margin: 0.5rem;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .p-carousel-next {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      margin: 0.5rem;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
  .p-carousel-indicators {
    padding: 1rem;
    .p-carousel-indicator {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      button {
        background-color: $background-color_5;
        width: 2rem;
        height: 0.5rem;
        transition: box-shadow 0.15s;
        border-radius: 0;
        &:hover {
          background: $border-color;
        }
      }
    }
    .p-carousel-indicator.p-highlight {
      button {
        background: #007bff;
        color: $color_4;
      }
    }
  }
}
.p-datatable {
  .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
  }
  .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
  }
  .p-datatable-header {
    background: #efefef;
    color: $color_3;
    border: solid $border-color;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-datatable-footer {
    background: #efefef;
    color: $color_3;
    border: 1px solid $border-color;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-datatable-thead {
    //background-color: $table-head-bg;
    //text-transform: uppercase;
    //height: 40px !important;
    border-top: 1px solid $border-color;
    text-transform: uppercase;
    //height: 40px !important;
    tr {
      th {
        //text-align: left;
        //padding: 1rem 1rem;
        //border: 1px solid $border-color;
        background: $table-head-bg;
        transition: box-shadow 0.15s;
        border-bottom: 0;
        padding: 0.75rem 1rem;
        font-weight: bold;
        font-size: 0.8rem;

        position: -webkit-sticky;
        position: sticky;
        top: -1px;
        //z-index: 1;
      }
    }
  }
  .p-datatable-tfoot {
    tr {
      td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid $border-color;
        border-width: 1px 0 1px 0;
        font-weight: 600;
        color: $color_3;
        background: #ffffff;
      }
    }
  }
  .p-sortable-column {
    .p-sortable-column-icon {
      color: $color_2;
      margin-left: 0.5rem;
    }
    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: $color_4;
      background: #007bff;
      margin-left: 0.5rem;
    }
    &:not(.p-highlight) {
      &:hover {
        background: #e9ecef;
        color: $color_3;
        .p-sortable-column-icon {
          color: $color_2;
        }
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
      outline: 0 none;
    }
  }
  .p-sortable-column.p-highlight {
    color: $primary;
    .p-sortable-column-icon {
      color: $primary;
    }
    &:hover {
      background: #e9ecef;
      color: $primary;
      .p-sortable-column-icon {
        color: $primary;
      }
    }
  }
  .p-datatable-tbody {
    tr {
      background: #ffffff;
      transition: box-shadow 0.15s;
      td {
        text-align: left;
        border: 1px solid $border-color;
        border-width: 1px 0 0 0;
        padding: 1rem 1rem;
        .p-row-toggler {
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
        .p-row-editor-init {
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
        .p-row-editor-save {
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
        .p-row-editor-cancel {
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
      }
      &:focus {
        outline: 0.15rem solid rgba(38, 143, 255, 0.5);
        outline-offset: 0.15rem;
      }
    }
    tr.p-highlight {
      background: #007bff;
      color: $color_4;
    }
    tr.p-datatable-dragpoint-top {
      td {
        box-shadow: inset 0 2px 0 0 #007bff;
      }
    }
    tr.p-datatable-dragpoint-bottom {
      td {
        box-shadow: inset 0 -2px 0 0 #007bff;
      }
    }
  }
  .p-column-resizer-helper {
    background: #007bff;
  }
  .p-datatable-scrollable-header {
    background: #efefef;
  }
  .p-datatable-scrollable-footer {
    background: #efefef;
  }
  .p-datatable-loading-overlay{
    background-color: $white !important;
    .p-datatable-loading-icon {
      font-size: 2rem;
    }
  }
  .p-checkbox{
    height: 16px !important;
    width: 16px !important;

    .p-checkbox-box{
      height: 16px !important;
      width: 16px !important;
    }
  }
}
.p-datatable.p-datatable-hoverable-rows {
  .p-datatable-tbody {
    tr {
      &:not(.p-highlight) {
        &:hover {
          background: #e9ecef;
          color: $color_3;
        }
      }
    }
  }
}
.p-datatable.p-datatable-scrollable {
  .p-datatable-wrapper {
    overflow: inherit!important;
    .p-datatable-table {
      .p-datatable-tfoot {
        background-color: $background-color_4;
      }
    }
  }
}
.p-datatable.p-datatable-gridlines {
  .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable-thead {
    tr {
      th {
        border-width: 1px 1px 2px 1px;
      }
    }
  }
  .p-datatable-tbody {
    tr {
      td {
        border-width: 1px;
      }
    }
  }
  .p-datatable-tfoot {
    tr {
      td {
        border-width: 1px;
      }
    }
  }
}
.p-datatable.p-datatable-striped {
  .p-datatable-tbody {
    tr {
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.05);
      }
      &:nth-child(even).p-highlight {
        background: #007bff;
        color: $color_4;
        .p-row-toggler {
          color: $color_4;
          &:hover {
            color: $color_4;
          }
        }
      }
    }
  }
}
.p-datatable.p-datatable-sm {
  .p-datatable-header {
    padding: 0.5rem 0.5rem;
  }
  .p-datatable-thead {
    tr {
      th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-tbody {
    tr {
      td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-tfoot {
    tr {
      td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-datatable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-datatable.p-datatable-lg {
  .p-datatable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-datatable-thead {
    tr {
      th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-tbody {
    tr {
      td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-tfoot {
    tr {
      td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-datatable-footer {
    padding: 1.25rem 1.25rem;
  }
}
.p-dataview {
  .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
  }
  .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
  }
  .p-dataview-header {
    background: #efefef;
    color: $color_3;
    border: solid $border-color;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-dataview-content {
    background: #ffffff;
    color: $color_3;
    border: 0 none;
    padding: 0;
  }
  .p-dataview-footer {
    background: #efefef;
    color: $color_3;
    border: 1px solid $border-color;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .p-dataview-loading-icon {
    font-size: 2rem;
  }
  .p-dataview-emptymessage {
    padding: 1.25rem;
  }
}
.p-dataview.p-dataview-list {
  .p-dataview-content {
    .p-grid {
      div {
        border: 1px solid $border-color;
        border-width: 1px 0 0 0;
      }
    }
  }
}
.p-column-filter-row {
  .p-column-filter-menu-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }
}
.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: $color_2;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  &:hover {
    color: $color_5;
    border-color: $border-color_4;
    background: transparent;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;

  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-open {
  background: transparent;
  color: $color_5;
  &:hover {
    background: transparent;
    color: $color_5;
  }
}
.p-column-filter-menu-button.p-column-filter-menu-button-active {
  background: #007bff;
  color: $color_4;
  &:hover {
    background: #007bff;
    color: $color_4;
  }
}
.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: $color_2;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  &:hover {
    color: $color_5;
    border-color: $border-color_4;
    background: transparent;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;

  }
}
.p-column-filter-overlay {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  min-width: 12.5rem;
  .p-column-filter-row-items {
    padding: 0.5rem 0;
    .p-column-filter-row-item {
      margin: 0;
      padding: 0.5rem 1.5rem;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 0;
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: $color_3;
            background: #e9ecef;
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
      }
    }
    .p-column-filter-row-item.p-highlight {
      color: $color_4;
      background: #007bff;
    }
    .p-column-filter-separator {
      border-top: 1px solid $border-color;
      margin: 0.5rem 0;
    }
  }
}
.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid $border-color;
    color: $color_3;
    background: #efefef;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid $border-color;
    .p-column-filter-matchmode-dropdown {
      margin-bottom: 0.5rem;
    }
    .p-column-filter-remove-button {
      margin-top: 0.5rem;
    }
    &:last-child {
      border-bottom: 0 none;
    }
  }
  .p-column-filter-add-rule {
    padding: 0.5rem 1.25rem;
  }
  .p-column-filter-buttonbar {
    padding: 1.25rem;
  }
}
.fc.fc-unthemed {
  .fc-view-container {
    th {
      background: #efefef;
      border: 1px solid $border-color;
      color: $color_3;
    }
    td.fc-widget-content {
      background: #ffffff;
      border: 1px solid $border-color;
      color: $color_3;
    }
    td.fc-head-container {
      border: 1px solid $border-color;
    }
    .fc-row {
      border-right: 1px solid $border-color;
    }
    .fc-event {
      background: #0069d9;
      border: 1px solid #0069d9;
      color: $color_4;
    }
    .fc-divider {
      background: #efefef;
      border: 1px solid $border-color;
    }
  }
  .fc-toolbar {
    .fc-button {
      color: $color_4;
      background: #007bff;
      border: 1px solid #007bff;
      font-size: 1rem;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      border-radius: 4px;
      display: flex;
      align-items: center;
      &:enabled {
        &:hover {
          background: #0069d9;
          color: $color_4;
          border-color: $border-color_9;
        }
        &:active {
          background: #0062cc;
          color: $color_4;
          border-color: $border-color_5;
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
      }
      .fc-icon-chevron-left {
        font-family: $font-family-icon !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e900";
        }
      }
      .fc-icon-chevron-right {
        font-family: $font-family-icon !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e901";
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .fc-button.fc-dayGridMonth-button {
      background: #6c757d;
      border: 1px solid #6c757d;
      color: $color_4;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

        z-index: 1;
      }
    }
    .fc-button.fc-timeGridWeek-button {
      background: #6c757d;
      border: 1px solid #6c757d;
      color: $color_4;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

        z-index: 1;
      }
    }
    .fc-button.fc-timeGridDay-button {
      background: #6c757d;
      border: 1px solid #6c757d;
      color: $color_4;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

        z-index: 1;
      }
    }
    .fc-button.fc-dayGridMonth-button.fc-button-active {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      &:hover {
        background: #545b62;
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .fc-button.fc-timeGridWeek-button.fc-button-active {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      &:hover {
        background: #545b62;
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .fc-button.fc-timeGridDay-button.fc-button-active {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      &:hover {
        background: #545b62;
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .fc-button-group {
      .fc-button {
        border-radius: 0;
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
.fc.fc-theme-standard {
  .fc-view-harness {
    .fc-scrollgrid {
      border-color: $border-color_21;
    }
    th {
      background: #efefef;
      border-color: $border-color_21;
      color: $color_3;
    }
    td {
      color: $color_3;
      border-color: $border-color_21;
    }
    .fc-view {
      background: #ffffff;
    }
    .fc-popover {
      background: none;
      border: 0 none;
      .fc-popover-header {
        border: 1px solid $border-color;
        padding: 1rem 1.25rem;
        background: #efefef;
        color: $color_3;
        .fc-popover-close {
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          font-family: $font-family-icon !important;
          font-size: 1rem;
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          &:before {
            content: "\e90b";
          }
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
      }
      .fc-popover-body {
        padding: 1.25rem;
        border: 1px solid $border-color;
        background: #ffffff;
        color: $color_3;
        border-top: 0 none;
      }
    }
    .fc-event.fc-daygrid-block-event {
      color: $color_4;
      background: #0069d9;
      border-color: $border-color_9;
      .fc-event-main {
        color: $color_4;
      }
    }
    .fc-event.fc-daygrid-dot-event {
      .fc-daygrid-event-dot {
        background: #0069d9;
        border-color: $border-color_9;
      }
      &:hover {
        background: #e9ecef;
        color: $color_3;
      }
    }
    .fc-cell-shaded {
      background: #efefef;
    }
  }
  .fc-toolbar {
    .fc-button {
      color: $color_4;
      background: #007bff;
      border: 1px solid #007bff;
      font-size: 1rem;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      border-radius: 4px;
      &:enabled {
        &:hover {
          background: #0069d9;
          color: $color_4;
          border-color: $border-color_9;
        }
        &:active {
          background: #0062cc;
          color: $color_4;
          border-color: $border-color_5;
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
      }
      &:disabled {
        opacity: 0.65;
        color: $color_4;
        background: #007bff;
        border: 1px solid #007bff;
      }
      .fc-icon-chevron-left {
        font-family: $font-family-icon !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e900";
        }
      }
      .fc-icon-chevron-right {
        font-family: $font-family-icon !important;
        text-indent: 0;
        font-size: 1rem;
        &:before {
          content: "\e901";
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .fc-button.fc-dayGridMonth-button {
      background: #6c757d;
      border: 1px solid #6c757d;
      color: $color_4;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
      }
      &:not(:disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;

          z-index: 1;
        }
      }
    }
    .fc-button.fc-timeGridWeek-button {
      background: #6c757d;
      border: 1px solid #6c757d;
      color: $color_4;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
      }
      &:not(:disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;

          z-index: 1;
        }
      }
    }
    .fc-button.fc-timeGridDay-button {
      background: #6c757d;
      border: 1px solid #6c757d;
      color: $color_4;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      &:hover {
        background: #5a6268;
        border-color: $border-color_7;
        color: $color_4;
      }
      &:not(:disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;

          z-index: 1;
        }
      }
    }
    .fc-button.fc-dayGridMonth-button.fc-button-active {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      &:hover {
        background: #545b62;
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .fc-button.fc-timeGridWeek-button.fc-button-active {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      &:hover {
        background: #545b62;
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .fc-button.fc-timeGridDay-button.fc-button-active {
      background: #545b62;
      border-color: $border-color_8;
      color: $color_4;
      &:hover {
        background: #545b62;
        border-color: $border-color_8;
        color: $color_4;
      }
    }
    .fc-button-group {
      .fc-button {
        border-radius: 0;
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  a {
    color: $color_3;
  }
  .fc-highlight {
    color: $color_4;
    background: #007bff;
    background: #cce5ff;
  }
}
.p-orderlist {
  .p-orderlist-controls {
    padding: 1.25rem;
    .p-button {
      margin-bottom: 0.5rem;
    }
  }
  .p-orderlist-header {
    background: #efefef;
    color: $color_3;
    border: 1px solid $border-color;
    padding: 1rem 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-orderlist-title {
      font-weight: 600;
    }
  }
  .p-orderlist-filter-container {
    padding: 1rem 1.25rem;
    background: #ffffff;
    border: 1px solid $border-color;
    border-bottom: 0 none;
    .p-orderlist-filter-input {
      padding-right: 1.75rem;
    }
    .p-orderlist-filter-icon {
      right: 0.75rem;
      color: $color_5;
    }
  }
  .p-orderlist-list {
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    padding: 0.5rem 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .p-orderlist-item {
      padding: 0.5rem 1.5rem;
      margin: 0;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
      }
    }
    .p-orderlist-item.p-highlight {
      color: $color_4;
      background: #007bff;
    }
    .p-orderlist-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
    }
    &:not(.cdk-drop-list-dragging) {
      .p-orderlist-item {
        &:not(.p-highlight) {
          &:hover {
            background: #e9ecef;
            color: $color_3;
          }
        }
      }
    }
  }
}
.p-orderlist.p-orderlist-striped {
  .p-orderlist-list {
    .p-orderlist-item {
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.05);
        &:hover {
          background: #e9ecef;
        }
      }
    }
  }
}
.p-orderlist-item.cdk-drag-preview {
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border: 0 none;
  color: $color_3;
  background: #ffffff;
  margin: 0;
}
.p-organizationchart {
  .p-organizationchart-node-content.p-organizationchart-selectable-node {
    &:not(.p-highlight) {
      &:hover {
        background: #e9ecef;
        color: $color_3;
      }
    }
  }
  .p-organizationchart-node-content.p-highlight {
    background: #007bff;
    color: $color_4;
    .p-node-toggler {
      i {
        color: $color_13;
      }
    }
  }
  .p-organizationchart-line-down {
    background: $border-color;
  }
  .p-organizationchart-line-left {
    border-right: 1px solid $border-color;
    border-color: $border-color_21;
  }
  .p-organizationchart-line-top {
    border-top: 1px solid $border-color;
    border-color: $border-color_21;
  }
  .p-organizationchart-node-content {
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    padding: 1.25rem;
    .p-node-toggler {
      background: inherit;
      color: $color_14;
      border-radius: 50%;
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
}
.p-paginator {
  background: $white;
  color: $gray-400;
  border: solid $border-color;
  border-width: 0;
  padding: 0.75rem;
  border-radius: 4px;
  .p-paginator-element{
    background-color: $background-color_4;
    border: 1px solid $border-color;
    color: $body-color;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0 0 0 -1px;
    transition: box-shadow 0.15s;
    border-radius: 0;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          color: $primary;
        }
      }
    }
  }
  .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.357rem;
    background-color: $white;
    border: 1px solid $border-color;
    color: $gray-400;
    min-width: 2.357rem;
    transition: box-shadow 0.15s;
    border-radius: 0;
    .p-dropdown-label {
      padding-right: 0;
    }
  }
  .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .p-inputtext {
      max-width: 2.357rem;
    }
  }
  .p-paginator-current {
    background-color: $primary;
    border: 1px solid $border-color;
    color: $white;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0 0 0 -1px;
    padding: 0 0.5rem;
  }
  .p-paginator-pages {
    .p-paginator-page.p-highlight {
      background: $primary;
      color: $white;
    }
  }
}
.p-picklist {
  .p-picklist-buttons {
    padding: 1.25rem;
    .p-button {
      margin-bottom: 0.5rem;
    }
  }
  .p-picklist-header {
    background: #efefef;
    color: $color_3;
    border: 1px solid $border-color;
    padding: 1rem 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-picklist-title {
      font-weight: 600;
    }
  }
  .p-picklist-filter-container {
    padding: 1rem 1.25rem;
    background: #ffffff;
    border: 1px solid $border-color;
    border-bottom: 0 none;
    .p-picklist-filter-input {
      padding-right: 1.75rem;
    }
    .p-picklist-filter-icon {
      right: 0.75rem;
      color: $color_5;
    }
  }
  .p-picklist-list {
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    padding: 0.5rem 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .p-picklist-item {
      padding: 0.5rem 1.5rem;
      margin: 0;
      border: 0 none;
      color: $color_3;
      background: transparent;
      transition: box-shadow 0.15s;
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
      }
    }
    .p-picklist-item.p-highlight {
      color: $color_4;
      background: #007bff;
    }
    .p-picklist-empty-message {
      padding: 0.5rem 1.5rem;
      color: $color_3;
    }
    &:not(.cdk-drop-list-dragging) {
      .p-picklist-item {
        &:not(.p-highlight) {
          &:hover {
            background: #e9ecef;
            color: $color_3;
          }
        }
      }
    }
  }
}
.p-picklist.p-picklist-striped {
  .p-picklist-list {
    .p-picklist-item {
      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.05);
        &:hover {
          background: #e9ecef;
        }
      }
    }
  }
}
.p-picklist-item.cdk-drag-preview {
  padding: 0.5rem 1.5rem;
  box-shadow: none;
  border: 0 none;
  color: $color_3;
  background: #ffffff;
  margin: 0;
}
.p-timeline {
  .p-timeline-event-marker {
    border: 0 none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: $background-color_6;
  }
  .p-timeline-event-connector {
    background-color: $background-color_7;
  }
}
.p-timeline.p-timeline-vertical {
  .p-timeline-event-opposite {
    padding: 0 1rem;
  }
  .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline-event-connector {
    width: 2px;
  }
}
.p-timeline.p-timeline-horizontal {
  .p-timeline-event-opposite {
    padding: 1rem 0;
  }
  .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline-event-connector {
    height: 2px;
  }
}
.p-tree {
  border: 1px solid $border-color;
  background: #ffffff;
  color: $color_3;
  padding: 1.25rem;
  border-radius: 4px;
  .p-tree-container {
    .p-treenode {
      padding: 0.143rem;
      .p-treenode-content {
        border-radius: 4px;
        transition: box-shadow 0.15s;
        padding: 0.286rem;
        .p-tree-toggler {
          margin-right: 0.5rem;
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
        .p-treenode-icon {
          margin-right: 0.5rem;
          color: $color_2;
        }
        .p-checkbox {
          margin-right: 0.5rem;
          .p-indeterminate {
            .p-checkbox-icon {
              color: $color_3;
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
        }
      }
      .p-treenode-content.p-highlight {
        background: #007bff;
        color: $color_4;
        .p-tree-toggler {
          color: $color_4;
          &:hover {
            color: $color_4;
          }
        }
        .p-treenode-icon {
          color: $color_4;
          &:hover {
            color: $color_4;
          }
        }
      }
      .p-treenode-content.p-treenode-selectable {
        &:not(.p-highlight) {
          &:hover {
            background: #e9ecef;
            color: $color_3;
          }
        }
      }
      .p-treenode-content.p-treenode-dragover {
        background: #e9ecef;
        color: $color_3;
      }
    }
  }
  .p-tree-filter-container {
    margin-bottom: 0.5rem;
    .p-tree-filter {
      width: 100%;
      padding-right: 1.75rem;
    }
    .p-tree-filter-icon {
      right: 0.75rem;
      color: $color_5;
    }
  }
  .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: $background-color_8;
  }
}
.p-tree.p-tree-horizontal {
  .p-treenode {
    .p-treenode-content {
      border-radius: 4px;
      border: 1px solid $border-color;
      background-color: $background-color_4;
      color: $color_3;
      padding: 0.286rem;
      transition: box-shadow 0.15s;
      .p-tree-toggler {
        margin-right: 0.5rem;
      }
      .p-treenode-icon {
        color: $color_2;
        margin-right: 0.5rem;
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
      .p-treenode-label {
        &:not(.p-highlight) {
          &:hover {
            background-color: $background-color_9;
            color: $color_14;
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .p-treenode-content.p-highlight {
      background-color: $background-color_6;
      color: $color_4;
      .p-treenode-icon {
        color: $color_4;
      }
    }
    .p-treenode-content.p-treenode-selectable {
      &:not(.p-highlight) {
        &:hover {
          background: #e9ecef;
          color: $color_3;
        }
      }
    }
  }
}
.p-treetable {
  .p-paginator-top {
    border-width: 1px 0 0 0;
    border-radius: 0;
  }
  .p-paginator-bottom {
    border-width: 1px 0 0 0;
    border-radius: 0;
  }
  .p-treetable-header {
    background: #efefef;
    color: $color_3;
    border: solid $border-color;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable-footer {
    background: #efefef;
    color: $color_3;
    border: 1px solid $border-color;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable-thead {
    tr {
      th {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid $border-color;
        border-width: 1px 0 2px 0;
        font-weight: 600;
        color: $color_3;
        background: #ffffff;
        transition: box-shadow 0.15s;
      }
    }
  }
  .p-treetable-tfoot {
    tr {
      td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid $border-color;
        border-width: 1px 0 1px 0;
        font-weight: 600;
        color: $color_3;
        background: #ffffff;
      }
    }
  }
  .p-sortable-column {
    outline-color: $outline-color_1;
    .p-sortable-column-icon {
      color: $color_2;
      margin-left: 0.5rem;
    }
    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: $color_4;
      background: #007bff;
      margin-left: 0.5rem;
    }
    &:not(.p-highlight) {
      &:hover {
        background: #e9ecef;
        color: $color_3;
        .p-sortable-column-icon {
          color: $color_2;
        }
      }
    }
  }
  .p-sortable-column.p-highlight {
    background: #ffffff;
    color: $primary;
    .p-sortable-column-icon {
      color: $primary;
    }
  }
  .p-treetable-tbody {
    tr {
      background: #ffffff;
      color: $color_3;
      transition: box-shadow 0.15s;
      td {
        text-align: left;
        border: 1px solid $border-color;
        border-width: 1px 0 0 0;
        padding: 1rem 1rem;
        .p-treetable-toggler {
          width: 2rem;
          height: 2rem;
          color: $color_2;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: box-shadow 0.15s;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: $color_5;
              border-color: $border-color_4;
              background: transparent;
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;

          }
        }
        p-treetablecheckbox {
          .p-checkbox {
            margin-right: 0.5rem;
            .p-indeterminate {
              .p-checkbox-icon {
                color: $color_3;
              }
            }
          }
        }
      }
      &:focus {
        outline: 0.15rem solid rgba(38, 143, 255, 0.5);
        outline-offset: -0.15rem;
      }
    }
    tr.p-highlight {
      background: #007bff;
      color: $color_4;
      .p-treetable-toggler {
        color: $color_4;
        &:hover {
          color: $color_4;
        }
      }
    }
  }
  .p-column-resizer-helper {
    background: #007bff;
  }
  .p-treetable-scrollable-header {
    background: #efefef;
  }
  .p-treetable-scrollable-footer {
    background: #efefef;
  }
  .p-treetable-loading-icon {
    font-size: 2rem;
  }
}
.p-treetable.p-treetable-hoverable-rows {
  .p-treetable-tbody {
    tr {
      &:not(.p-highlight) {
        &:hover {
          background: #e9ecef;
          color: $color_3;
          .p-treetable-toggler {
            color: $color_3;
          }
        }
      }
    }
  }
}
.p-treetable.p-treetable-gridlines {
  .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-thead {
    tr {
      th {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tbody {
    tr {
      td {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tfoot {
    tr {
      td {
        border-width: 1px;
      }
    }
  }
}
.p-treetable.p-treetable-sm {
  .p-treetable-header {
    padding: 0.875rem 0.875rem;
  }
  .p-treetable-thead {
    tr {
      th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tbody {
    tr {
      td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tfoot {
    tr {
      td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-treetable.p-treetable-lg {
  .p-treetable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable-thead {
    tr {
      th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tbody {
    tr {
      td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tfoot {
    tr {
      td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 1.25rem 1.25rem;
  }
}
.p-virtualscroller {
  .p-virtualscroller-header {
    background: #efefef;
    color: $color_3;
    border: solid $border-color;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-virtualscroller-content {
    background: #ffffff;
    color: $color_3;
    border: 0 none;
    padding: 0;
  }
  .p-virtualscroller-footer {
    background: #efefef;
    color: $color_3;
    border: 1px solid $border-color;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 1rem 1.25rem;
      border: 1px solid $border-color;
      color: $color_3;
      background: #efefef;
      font-weight: 600;
      border-radius: 4px;
      transition: box-shadow 0.15s;
      .p-accordion-toggle-icon {
        margin-right: 0.5rem;
      }
    }
    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus {
          outline: 0 none;
          outline-offset: 0;

        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: #e9ecef;
            border-color: $border-color_21;
            color: $color_3;
          }
        }
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: #efefef;
        border-color: $border-color_21;
        color: $color_3;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:hover {
        .p-accordion-header-link {
          border-color: $border-color_21;
          background: #e9ecef;
          color: $color_3;
        }
      }
    }
  }
  .p-accordion-content {
    padding: 1.25rem;
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  p-accordiontab {
    .p-accordion-tab {
      margin-bottom: 0;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        border-radius: 0;
      }
    }
    .p-accordion-content {
      border-radius: 0;
    }
    &:not(:first-child) {
      .p-accordion-header {
        .p-accordion-header-link {
          border-top: 0 none;
        }
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              .p-accordion-header-link {
                border-top: 0 none;
              }
            }
          }
        }
        &:not(.p-disabled).p-highlight {
          &:hover {
            .p-accordion-header-link {
              border-top: 0 none;
            }
          }
        }
      }
    }
    &:first-child {
      .p-accordion-header {
        .p-accordion-header-link {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
    &:last-child {
      .p-accordion-header {
        &:not(.p-highlight) {
          .p-accordion-header-link {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
      .p-accordion-content {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}
.p-card {
  background: #ffffff;
  color: $color_3;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .p-card-body {
    padding: 1.5rem;
  }
  .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: $color_2;
  }
  .p-card-content {
    padding: 1rem 0;
  }
  .p-card-footer {
    padding: 1rem 0 0 0;
  }
}
.p-divider {
  .p-divider-content {
    background-color: $background-color_4;
  }
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
  &:before {
    border-top: 1px $border-color;
  }
  .p-divider-content {
    padding: 0 0.5rem;
  }
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
  &:before {
    border-left: 1px $border-color;
  }
  .p-divider-content {
    padding: 0.5rem 0;
  }
}
.p-fieldset {
  border: 1px solid $border-color;
  background: #ffffff;
  color: $color_3;
  border-radius: 4px;
  .p-fieldset-legend {
    padding: 1rem 1.25rem;
    border: 1px solid $border-color;
    color: $color_3;
    background: #efefef;
    font-weight: 600;
    border-radius: 4px;
  }
  .p-fieldset-content {
    padding: 1.25rem;
  }
}
.p-fieldset.p-fieldset-toggleable {
  .p-fieldset-legend {
    padding: 0;
    transition: box-shadow 0.15s;
    a {
      padding: 1rem 1.25rem;
      color: $color_3;
      border-radius: 4px;
      transition: box-shadow 0.15s;
      .p-fieldset-toggler {
        margin-right: 0.5rem;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    &:hover {
      background: #e9ecef;
      border-color: $border-color_21;
      color: $color_3;
    }
  }
}
.p-panel {
  .p-panel-header {
    border: 1px solid $border-color;
    padding: 1rem 1.25rem;
    background: #efefef;
    color: $color_3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-panel-title {
      font-weight: 600;
    }
    .p-panel-header-icon {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
  .p-panel-content {
    padding: 1.25rem;
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 0 none;
  }
  .p-panel-footer {
    padding: 0.5rem 1.25rem;
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    border-top: 0 none;
  }
  .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }
}
.p-panel.p-panel-toggleable {
  .p-panel-header {
    padding: 0.5rem 1.25rem;
  }
}
.p-scrollpanel {
  .p-scrollpanel-bar {
    background: #efefef;
    border: 0 none;
  }
}
.p-splitter {
  border: 1px solid $border-color;
  background: #ffffff;
  border-radius: 4px;
  color: $color_3;
  .p-splitter-gutter {
    transition: box-shadow 0.15s;
    background: #efefef;
    .p-splitter-gutter-handle {
      background: $border-color;
    }
  }
  .p-splitter-gutter-resizing {
    background: $border-color;
  }
}
.p-tabview {
  .p-tabview-nav {
    background: transparent;
    border: 1px solid $border-color;
    border-width: 0 0 1px 0;
    li {
      margin-right: 0;
      .p-tabview-nav-link {
        border: solid;
        border-width: 1px;
        border-color: $border-color_22;
        background: #ffffff;
        color: $color_2;
        padding: 0.75rem 1rem;
        font-weight: 600;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        transition: box-shadow 0.15s;
        margin: 0 0 -1px 0;
        &:not(.p-disabled) {
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-tabview-nav-link {
              background: #ffffff;
              border-color: $border-color_21;
              color: $color_2;
            }
          }
        }
      }
    }
    li.p-highlight {
      .p-tabview-nav-link {
        background: #ffffff;
        border-color: $border-color_23;
        color: $color_5;
      }
    }
  }
  .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview-nav-btn.p-link {
    background: #ffffff;
    color: $color_5;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
  }
  .p-tabview-panels {
    background: #ffffff;
    padding: 1.25rem;
    border: 0 none;
    color: $color_3;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
.p-toolbar {
  background: #efefef;
  border: 1px solid $border-color;
  padding: 1rem 1.25rem;
  border-radius: 4px;
  .p-toolbar-separator {
    margin: 0 0.5rem;
  }
}
.p-confirm-popup {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
  .p-confirm-popup-content {
    padding: 1.25rem;
  }
  .p-confirm-popup-footer {
    text-align: right;
    padding: 0.5rem 1.25rem;
    button {
      margin: 0 0.5rem 0 0;
      width: auto;
      &:last-child {
        margin: 0;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: $border-color_24;
    border-bottom-color: $border-bottom-color_1;
  }
  &:before {
    border: solid transparent;
    border-color: $border-color_25;
    border-bottom-color: $border-bottom-color_2;
  }
  .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup-message {
    margin-left: 1rem;
  }
}
.p-confirm-popup.p-confirm-popup-flipped {
  &:after {
    border-top-color: $border-top-color_1;
  }
  &:before {
    border-top-color: $border-top-color_2;
  }
}
.p-dialog {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  .p-dialog-header {
    border-bottom: 1px solid #e9ecef;
    background: #ffffff;
    color: $color_3;
    padding: 1rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-dialog-title {
      font-weight: 600;
      font-size: 1.25rem;
    }
    .p-dialog-header-icon {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      margin-right: 0.5rem;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .p-dialog-content {
    background: #ffffff;
    color: $color_3;
    padding: 1rem;
  }
  .p-dialog-footer {
    border-top: 1px solid #e9ecef;
    background: #ffffff;
    color: $color_3;
    padding: 1rem;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }
  }
}
.p-dialog.p-confirm-dialog {
  .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-confirm-dialog-message {
    margin-left: 1rem;
  }
}
.p-overlaypanel {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
  .p-overlaypanel-content {
    padding: 1.25rem;
  }
  .p-overlaypanel-close {
    background: #007bff;
    color: $color_4;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 0.15s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
    &:enabled {
      &:hover {
        background: #0069d9;
        color: $color_4;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: $border-color_24;
    border-bottom-color: $border-bottom-color_1;
  }
  &:before {
    border: solid transparent;
    border-color: $border-color_25;
    border-bottom-color: $border-bottom-color_2;
  }
}
.p-overlaypanel.p-overlaypanel-flipped {
  &:after {
    border-top-color: $border-top-color_1;
  }
  &:before {
    border-top-color: $border-top-color_2;
  }
}
.p-sidebar {
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  .p-sidebar-header {
    padding: 1rem 1.25rem;
    .p-sidebar-close {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .p-sidebar-icon {
      width: 2rem;
      height: 2rem;
      color: $color_2;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:enabled {
        &:hover {
          color: $color_5;
          border-color: $border-color_4;
          background: transparent;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    + {
      .p-sidebar-content {
        padding-top: 0;
      }
    }
  }
  .p-sidebar-content {
    padding: 1.25rem;
  }
}
.p-tooltip {
  .p-tooltip-text {
    background: #212529;
    color: $color_4;
    padding: 0.5rem 0.75rem;
    box-shadow: none;
    border-radius: 4px;
  }
}
.p-tooltip.p-tooltip-right {
  .p-tooltip-arrow {
    border-right-color: $border-right-color_1;
  }
}
.p-tooltip.p-tooltip-left {
  .p-tooltip-arrow {
    border-left-color: $border-left-color_1;
  }
}
.p-tooltip.p-tooltip-top {
  .p-tooltip-arrow {
    border-top-color: $border-top-color_3;
  }
}
.p-tooltip.p-tooltip-bottom {
  .p-tooltip-arrow {
    border-bottom-color: $border-bottom-color_3;
  }
}
.p-fileupload {
  .p-fileupload-buttonbar {
    background: #efefef;
    padding: 1rem 1.25rem;
    border: 1px solid $border-color;
    color: $color_3;
    border-bottom: 0 none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .p-button {
      margin-right: 0.5rem;
    }
    .p-button.p-fileupload-choose.p-focus {
      outline: 0 none;
      outline-offset: 0;

    }
  }
  .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid $border-color;
    color: $color_3;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .p-progressbar {
    height: 0.25rem;
  }
  .p-fileupload-row {
    div {
      padding: 1rem 1rem;
    }
  }
}
.p-fileupload.p-fileupload-advanced {
  .p-message {
    margin-top: 0;
  }
}
.p-fileupload-choose {
  &:not(.p-disabled) {
    &:hover {
      background: #0069d9;
      color: $color_4;
      border-color: $border-color_9;
    }
    &:active {
      background: #0062cc;
      color: $color_4;
      border-color: $border-color_5;
    }
  }
}
.p-breadcrumb {
  background: #efefef;
  border: 0 none;
  border-radius: 4px;
  padding: 1rem;
  ul {
    li {
      .p-menuitem-link {
        transition: box-shadow 0.15s;
        border-radius: 4px;
        &:focus {
          outline: 0 none;
          outline-offset: 0;

        }
        .p-menuitem-text {
          color: $primary;
        }
        .p-menuitem-icon {
          color: $primary;
        }
      }
      &:last-child {
        .p-menuitem-text {
          color: $color_2;
        }
        .p-menuitem-icon {
          color: $color_2;
        }
      }
    }
    li.p-breadcrumb-chevron {
      margin: 0 0.5rem 0 0.5rem;
      color: $color_2;
    }
  }
  .p-breadcrumb-chevron {
    &:before {
      content: "/";
    }
  }
}
.p-contextmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: $color_3;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  width: 12.5rem;
  .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $color_3;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
    .p-menuitem-text {
      color: $color_3;
    }
    .p-menuitem-icon {
      color: $color_3;
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: $color_3;
    }
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        .p-menuitem-text {
          color: $color_3;
        }
        .p-menuitem-icon {
          color: $color_3;
        }
        .p-submenu-icon {
          color: $color_3;
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    border-radius: 4px;
  }
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
  .p-menuitem.p-menuitem-active {
    .p-menuitem-link {
      background: #e9ecef;
      .p-menuitem-text {
        color: $color_3;
      }
      .p-menuitem-icon {
        color: $color_3;
      }
      .p-submenu-icon {
        color: $color_3;
      }
    }
  }
  .p-menu-separator {
    border-top: 1px solid $border-color;
    margin: 0.5rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
}
.p-dock {
  .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }
  .p-dock-item {
    padding: 0.5rem;
  }
  .p-dock-action {
    width: 4rem;
    height: 4rem;
  }
}
.p-dock.p-dock-top {
  .p-dock-item-second-prev {
    margin: 0 0.9rem;
  }
  .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock-item-prev {
    margin: 0 1.3rem;
  }
  .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock-item-current {
    margin: 0 1.5rem;
  }
}
.p-dock.p-dock-bottom {
  .p-dock-item-second-prev {
    margin: 0 0.9rem;
  }
  .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock-item-prev {
    margin: 0 1.3rem;
  }
  .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock-item-current {
    margin: 0 1.5rem;
  }
}
.p-dock.p-dock-left {
  .p-dock-item-second-prev {
    margin: 0.9rem 0;
  }
  .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock-item-prev {
    margin: 1.3rem 0;
  }
  .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock-item-current {
    margin: 1.5rem 0;
  }
}
.p-dock.p-dock-right {
  .p-dock-item-second-prev {
    margin: 0.9rem 0;
  }
  .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock-item-prev {
    margin: 1.3rem 0;
  }
  .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock-item-current {
    margin: 1.5rem 0;
  }
}
.p-megamenu {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: $color_15;
  border: 0 none;
  border-radius: 4px;
  .p-megamenu-root-list {
    .p-menuitem {
      .p-menuitem-link {
        padding: 1rem;
        color: $color_16;
        border-radius: 4px;
        transition: box-shadow 0.15s;
        user-select: none;
        .p-menuitem-text {
          color: $color_16;
        }
        .p-menuitem-icon {
          color: $color_16;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_16;
          margin-left: 0.5rem;
        }
        &:not(.p-disabled) {
          &:hover {
            background: transparent;
            .p-menuitem-text {
              color: $color_17;
            }
            .p-menuitem-icon {
              color: $color_17;
            }
            .p-submenu-icon {
              color: $color_17;
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
        }
      }
    }
    .p-menuitem.p-menuitem-active {
      .p-menuitem-link {
        background: transparent;
        &:not(.p-disabled) {
          &:hover {
            background: transparent;
            .p-menuitem-text {
              color: $color_15;
            }
            .p-menuitem-icon {
              color: $color_15;
            }
            .p-submenu-icon {
              color: $color_15;
            }
          }
        }
        .p-menuitem-text {
          color: $color_15;
        }
        .p-menuitem-icon {
          color: $color_15;
        }
        .p-submenu-icon {
          color: $color_15;
        }
      }
    }
  }
  .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $color_3;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
    .p-menuitem-text {
      color: $color_3;
    }
    .p-menuitem-icon {
      color: $color_3;
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: $color_3;
    }
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        .p-menuitem-text {
          color: $color_3;
        }
        .p-menuitem-icon {
          color: $color_3;
        }
        .p-submenu-icon {
          color: $color_3;
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
  }
  .p-megamenu-panel {
    background: #ffffff;
    color: $color_3;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
  }
  .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $color_3;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .p-megamenu-submenu {
    padding: 0.5rem 0;
    width: 12.5rem;
    .p-menu-separator {
      border-top: 1px solid $border-color;
      margin: 0.5rem 0;
    }
    .p-menuitem {
      margin: 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-menuitem.p-menuitem-active {
    .p-menuitem-link {
      background: #e9ecef;
      .p-menuitem-text {
        color: $color_3;
      }
      .p-menuitem-icon {
        color: $color_3;
      }
      .p-submenu-icon {
        color: $color_3;
      }
    }
  }
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
}
.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: $color_3;
  //border: 1px solid $border-color;
  border-radius: 4px;
  width: 12.5rem;
  box-shadow: $dropdown-box-shadow;
  .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $color_3;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
    .p-menuitem-text {
      color: $color_3;
    }
    .p-menuitem-icon {
      color: $color_3;
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: $color_3;
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba($primary,.12);
        .p-menuitem-text {
          color: $primary;
        }
        .p-menuitem-icon {
          color: $primary;
        }
        .p-submenu-icon {
          color: $primary;
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
    }
  }
  .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $color_3;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu-separator {
    border-top: 1px solid $border-color;
    margin: 0.5rem 0;
  }
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  //border: 1px solid rgba(0, 0, 0, 0.15);
  //box-shadow: none;
}
.p-menubar {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: $color_15;
  border: 0 none;
  border-radius: 4px;
  .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $color_3;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
    .p-menuitem-text {
      color: $color_3;
    }
    .p-menuitem-icon {
      color: $color_3;
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: $color_3;
    }
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        .p-menuitem-text {
          color: $color_3;
        }
        .p-menuitem-icon {
          color: $color_3;
        }
        .p-submenu-icon {
          color: $color_3;
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
  }
  .p-menubar-root-list {
    .p-menuitem {
      .p-menuitem-link {
        padding: 1rem;
        color: $color_16;
        border-radius: 4px;
        transition: box-shadow 0.15s;
        user-select: none;
        .p-menuitem-text {
          color: $color_16;
        }
        .p-menuitem-icon {
          color: $color_16;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_16;
          margin-left: 0.5rem;
        }
        &:not(.p-disabled) {
          &:hover {
            background: transparent;
            .p-menuitem-text {
              color: $color_17;
            }
            .p-menuitem-icon {
              color: $color_17;
            }
            .p-submenu-icon {
              color: $color_17;
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
        }
      }
    }
    .p-menuitem.p-menuitem-active {
      .p-menuitem-link {
        background: transparent;
        &:not(.p-disabled) {
          &:hover {
            background: transparent;
            .p-menuitem-text {
              color: $color_15;
            }
            .p-menuitem-icon {
              color: $color_15;
            }
            .p-submenu-icon {
              color: $color_15;
            }
          }
        }
        .p-menuitem-text {
          color: $color_15;
        }
        .p-menuitem-icon {
          color: $color_15;
        }
        .p-submenu-icon {
          color: $color_15;
        }
      }
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 12.5rem;
    .p-menu-separator {
      border-top: 1px solid $border-color;
      margin: 0.5rem 0;
    }
    .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menuitem {
      margin: 0;
      &:last-child {
        margin: 0;
      }
    }
  }
  .p-menuitem.p-menuitem-active {
    .p-menuitem-link {
      background: #e9ecef;
      .p-menuitem-text {
        color: $color_3;
      }
      .p-menuitem-icon {
        color: $color_3;
      }
      .p-submenu-icon {
        color: $color_3;
      }
    }
  }
}
.p-panelmenu {
  .p-panelmenu-header {
    a {
      padding: 1rem 1.25rem;
      border: 1px solid $border-color;
      color: $color_3;
      background: #efefef;
      font-weight: 600;
      border-radius: 4px;
      transition: box-shadow 0.15s;
      .p-panelmenu-icon {
        margin-right: 0.5rem;
      }
      .p-menuitem-icon {
        margin-right: 0.5rem;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        a {
          &:hover {
            background: #e9ecef;
            border-color: $border-color_21;
            color: $color_3;
          }
        }
      }
    }
  }
  .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
    a {
      background: #efefef;
      border-color: $border-color_21;
      color: $color_3;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(.p-disabled) {
      a {
        &:hover {
          border-color: $border-color_21;
          background: #e9ecef;
          color: $color_3;
        }
      }
    }
  }
  .p-panelmenu-content {
    padding: 0.5rem 0;
    border: 1px solid $border-color;
    background: #ffffff;
    color: $color_3;
    margin-bottom: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .p-menuitem {
      .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: $color_3;
        border-radius: 0;
        transition: box-shadow 0.15s;
        user-select: none;
        .p-menuitem-text {
          color: $color_3;
        }
        .p-menuitem-icon {
          color: $color_3;
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: $color_3;
        }
        &:not(.p-disabled) {
          &:hover {
            background: #e9ecef;
            .p-menuitem-text {
              color: $color_3;
            }
            .p-menuitem-icon {
              color: $color_3;
            }
            .p-submenu-icon {
              color: $color_3;
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
        }
        .p-panelmenu-icon {
          margin-right: 0.5rem;
        }
      }
    }
    .p-submenu-list {
      &:not(.p-panelmenu-root-submenu) {
        padding: 0 0 0 1rem;
      }
    }
  }
  .p-panelmenu-panel {
    margin-bottom: 0;
    .p-panelmenu-header {
      a {
        border-radius: 0;
      }
    }
    .p-panelmenu-content {
      border-radius: 0;
    }
    &:not(:first-child) {
      .p-panelmenu-header {
        a {
          border-top: 0 none;
        }
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              a {
                border-top: 0 none;
              }
            }
          }
        }
        &:not(.p-disabled).p-highlight {
          &:hover {
            a {
              border-top: 0 none;
            }
          }
        }
      }
    }
    &:first-child {
      .p-panelmenu-header {
        a {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
    &:last-child {
      .p-panelmenu-header {
        &:not(.p-highlight) {
          a {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
      }
      .p-panelmenu-content {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}
.p-slidemenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: $color_3;
  border: 1px solid $border-color;
  border-radius: 4px;
  width: 12.5rem;
  .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $color_3;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
    .p-menuitem-text {
      color: $color_3;
    }
    .p-menuitem-icon {
      color: $color_3;
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: $color_3;
    }
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        .p-menuitem-text {
          color: $color_3;
        }
        .p-menuitem-icon {
          color: $color_3;
        }
        .p-submenu-icon {
          color: $color_3;
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
  }
  .p-slidemenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
  }
  .p-slidemenu.p-slidemenu-active {
    .p-slidemenu-link {
      background: #e9ecef;
      .p-slidemenu-text {
        color: $color_3;
      }
      .p-slidemenu-icon {
        color: $color_3;
        color: $color_3;
      }
    }
  }
  .p-slidemenu-separator {
    border-top: 1px solid $border-color;
    margin: 0.5rem 0;
  }
  .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: $color_3;
  }
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-steps {
  .p-steps-item {
    .p-menuitem-link {
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 4px;
      background: transparent;
      .p-steps-number {
        color: $color_3;
        border: 1px solid $border-color;
        background: transparent;
        min-width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 4px;
      }
      .p-steps-title {
        margin-top: 0.5rem;
        color: $color_2;
      }
      &:not(.p-disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;

        }
      }
    }
    &:before {
      content: " ";
      border-top: 1px solid $border-color;
      width: 100%;
      top: 50%;
      left: 0;
      display: block;
      position: absolute;
      margin-top: -1rem;
    }
  }
  .p-steps-item.p-highlight {
    .p-steps-number {
      background: #007bff;
      color: $color_4;
    }
    .p-steps-title {
      font-weight: 600;
      color: $color_3;
    }
  }
}
.p-tabmenu {
  .p-tabmenu-nav {
    background: transparent;
    border: 1px solid $border-color;
    border-width: 0 0 1px 0;
    .p-tabmenuitem {
      margin-right: 0;
      .p-menuitem-link {
        border: solid;
        border-width: 1px;
        border-color: $border-color_22;
        background: #ffffff;
        color: $color_2;
        padding: 0.75rem 1rem;
        font-weight: 600;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        transition: box-shadow 0.15s;
        margin: 0 0 -1px 0;
        .p-menuitem-icon {
          margin-right: 0.5rem;
        }
        &:not(.p-disabled) {
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-menuitem-link {
              background: #ffffff;
              border-color: $border-color_21;
              color: $color_2;
            }
          }
        }
      }
    }
    .p-tabmenuitem.p-highlight {
      .p-menuitem-link {
        background: #ffffff;
        border-color: $border-color_23;
        color: $color_5;
      }
    }
  }
  .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu-nav-btn.p-link {
    background: #ffffff;
    color: $color_5;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
  }
}
.p-tieredmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: $color_3;
  border: 1px solid $border-color;
  border-radius: 4px;
  width: 12.5rem;
  .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $color_3;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
    .p-menuitem-text {
      color: $color_3;
    }
    .p-menuitem-icon {
      color: $color_3;
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: $color_3;
    }
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        .p-menuitem-text {
          color: $color_3;
        }
        .p-menuitem-icon {
          color: $color_3;
        }
        .p-submenu-icon {
          color: $color_3;
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
  }
  .p-menuitem {
    margin: 0;
    &:last-child {
      margin: 0;
    }
  }
  .p-menuitem.p-menuitem-active {
    .p-menuitem-link {
      background: #e9ecef;
      .p-menuitem-text {
        color: $color_3;
      }
      .p-menuitem-icon {
        color: $color_3;
      }
      .p-submenu-icon {
        color: $color_3;
      }
    }
  }
  .p-menu-separator {
    border-top: 1px solid $border-color;
    margin: 0.5rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}
.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 4px;
  .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message-text {
    font-size: 1rem;
  }
}
.p-inline-message.p-inline-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 0px;
  color: $color_18;
  .p-inline-message-icon {
    color: $color_18;
  }
}
.p-inline-message.p-inline-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 0px;
  color: $color_19;
  .p-inline-message-icon {
    color: $color_19;
  }
}
.p-inline-message.p-inline-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 0px;
  color: $color_20;
  .p-inline-message-icon {
    color: $color_20;
  }
}
.p-inline-message.p-inline-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 0px;
  color: $color_21;
  .p-inline-message-icon {
    color: $color_21;
  }
}
.p-inline-message.p-inline-message-icon-only {
  .p-inline-message-icon {
    margin-right: 0;
  }
}
.p-message {
  margin: 1rem 0;
  border-radius: 4px;
  .p-message-wrapper {
    padding: 1rem 1.25rem;
  }
  .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: box-shadow 0.15s;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;

    }
  }
  .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .p-message-summary {
    font-weight: 700;
  }
  .p-message-detail {
    margin-left: 0.5rem;
  }
}
.p-message.p-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: $color_18;
  .p-message-icon {
    color: $color_18;
  }
  .p-message-close {
    color: $color_18;
  }
}
.p-message.p-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: $color_19;
  .p-message-icon {
    color: $color_19;
  }
  .p-message-close {
    color: $color_19;
  }
}
.p-message.p-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: $color_20;
  .p-message-icon {
    color: $color_20;
  }
  .p-message-close {
    color: $color_20;
  }
}
.p-message.p-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: $color_21;
  .p-message-icon {
    color: $color_21;
  }
  .p-message-close {
    color: $color_21;
  }
}
.p-toast {
  opacity: 1;
  .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .p-toast-message-content {
      padding: 1rem;
      border-width: 0;
      .p-toast-message-text {
        margin: 0 0 0 1rem;
      }
      .p-toast-message-icon {
        font-size: 2rem;
      }
      .p-toast-summary {
        font-weight: 700;
      }
      .p-toast-detail {
        margin: 0.5rem 0 0 0;
      }
    }
    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: transparent;
      transition: box-shadow 0.15s;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
  .p-toast-message.p-toast-message-info {
    background: #cce5ff;
    border: solid #b8daff;
    border-width: 1px;
    color: $color_18;
    .p-toast-message-icon {
      color: $color_18;
    }
    .p-toast-icon-close {
      color: $color_18;
    }
  }
  .p-toast-message.p-toast-message-success {
    background: #d4edda;
    border: solid #c3e6cb;
    border-width: 1px;
    color: $color_19;
    .p-toast-message-icon {
      color: $color_19;
    }
    .p-toast-icon-close {
      color: $color_19;
    }
  }
  .p-toast-message.p-toast-message-warn {
    background: #fff3cd;
    border: solid #ffeeba;
    border-width: 1px;
    color: $color_20;
    .p-toast-message-icon {
      color: $color_20;
    }
    .p-toast-icon-close {
      color: $color_20;
    }
  }
  .p-toast-message.p-toast-message-error {
    background: #f8d7da;
    border: solid #f5c6cb;
    border-width: 1px;
    color: $color_21;
    .p-toast-message-icon {
      color: $color_21;
    }
    .p-toast-icon-close {
      color: $color_21;
    }
  }
}
.p-galleria {
  .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: $color_22;
    width: 4rem;
    height: 4rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
    .p-galleria-close-icon {
      font-size: 2rem;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: $color_22;
    }
  }
  .p-galleria-item-nav {
    background: transparent;
    color: $color_22;
    width: 4rem;
    height: 4rem;
    transition: box-shadow 0.15s;
    border-radius: 4px;
    margin: 0 0.5rem;
    .p-galleria-item-prev-icon {
      font-size: 2rem;
    }
    .p-galleria-item-next-icon {
      font-size: 2rem;
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_22;
      }
    }
  }
  .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: $color_22;
    padding: 1rem;
  }
  .p-galleria-indicators {
    padding: 1rem;
    .p-galleria-indicator {
      button {
        background-color: $background-color_5;
        width: 1rem;
        height: 1rem;
        transition: box-shadow 0.15s;
        border-radius: 4px;
        &:hover {
          background: $border-color;
        }
      }
    }
    .p-galleria-indicator.p-highlight {
      button {
        background: #007bff;
        color: $color_4;
      }
    }
  }
  .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
    .p-galleria-thumbnail-prev {
      margin: 0.5rem;
      background-color: $background-color_3;
      color: $color_22;
      width: 2rem;
      height: 2rem;
      transition: box-shadow 0.15s;
      border-radius: 4px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_22;
      }
    }
    .p-galleria-thumbnail-next {
      margin: 0.5rem;
      background-color: $background-color_3;
      color: $color_22;
      width: 2rem;
      height: 2rem;
      transition: box-shadow 0.15s;
      border-radius: 4px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: $color_22;
      }
    }
    .p-galleria-thumbnail-item-content {
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
  }
}
.p-galleria.p-galleria-indicators-bottom {
  .p-galleria-indicator {
    margin-right: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-top {
  .p-galleria-indicator {
    margin-right: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-left {
  .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
}
.p-galleria.p-galleria-indicators-right {
  .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
}
.p-galleria.p-galleria-indicator-onitem {
  .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
    .p-galleria-indicator {
      button {
        background: rgba(255, 255, 255, 0.4);
        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }
    }
    .p-galleria-indicator.p-highlight {
      button {
        background: #007bff;
        color: $color_4;
      }
    }
  }
}
.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}
.p-image-preview-indicator {
  background-color: $background-color_3;
  color: $color_23;
  transition: box-shadow 0.15s;
}
.p-image-preview-container {
  &:hover {
    .p-image-preview-indicator {
      background-color: $background-color_10;
    }
  }
}
.p-image-toolbar {
  padding: 1rem;
}
.p-image-action.p-link {
  color: $color_23;
  background-color: $background-color_3;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: $color_23;
    background-color: $background-color_11;
  }
  i {
    font-size: 1.5rem;
  }
}
.p-avatar {
  background-color: $background-color_7;
  border-radius: 4px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  .p-avatar-icon {
    font-size: 1.5rem;
  }
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  .p-avatar-icon {
    font-size: 2rem;
  }
}
.p-avatar-group {
  .p-avatar {
    border: 2px solid #ffffff;
  }
}
.p-badge {
  background: #007bff;
  color: $color_4;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: $background-color_12;
  color: $color_4;
}
.p-badge.p-badge-success {
  background-color: $background-color_13;
  color: $color_4;
}
.p-badge.p-badge-info {
  background-color: $background-color_14;
  color: $color_4;
}
.p-badge.p-badge-warning {
  background-color: $background-color_15;
  color: $color_3;
}
.p-badge.p-badge-danger {
  background-color: $background-color_16;
  color: $color_4;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-chip {
  background-color: $background-color_7;
  color: $color_3;
  border-radius: 16px;
  padding: 0 0.75rem;
  .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .p-chip-icon {
    margin-right: 0.5rem;
  }
  .pi-chip-remove-icon {
    margin-left: 0.5rem;
    border-radius: 4px;
    transition: box-shadow 0.15s;
    &:focus {
      outline: 0 none;
      outline-offset: 0;

    }
  }
  img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
  }
}
.p-inplace {
  .p-inplace-display {
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        color: $color_3;
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;

    }
  }
}
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #e9ecef;
  border-radius: 4px;
  .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #007bff;
  }
  .p-progressbar-label {
    color: $color_4;
    line-height: 1.5rem;
  }
}
.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  box-shadow: none;
  transition: box-shadow 0.15s;
  .p-scrolltop-icon {
    font-size: 1.5rem;
    color: $color_22;
  }
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}
.p-skeleton {
  background-color: $background-color_5;
  border-radius: 4px;
  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  }
}
.p-tag {
  background: #007bff;
  color: $color_4;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
}
.p-tag.p-tag-success {
  background-color: $background-color_13;
  color: $color_4;
}
.p-tag.p-tag-info {
  background-color: $background-color_14;
  color: $color_4;
}
.p-tag.p-tag-warning {
  background-color: $background-color_15;
  color: $color_3;
}
.p-tag.p-tag-danger {
  background-color: $background-color_16;
  color: $color_4;
}
.p-terminal {
  background: #ffffff;
  color: $color_3;
  border: 1px solid $border-color;
  padding: 1.25rem;
  .p-terminal-input {
    font-size: 1rem;
  }
}
@media screen and (max-width: 769px) {
  .p-datepicker {
    table {
      th {
        padding: 0;
      }
      td {
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .p-dock.p-dock-top {
    .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
      .p-dock-list {
        margin: 0 auto;
      }
    }
  }
  .p-dock.p-dock-bottom {
    .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
      .p-dock-list {
        margin: 0 auto;
      }
    }
  }
  .p-dock.p-dock-left {
    .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
      .p-dock-list {
        margin: auto 0;
      }
    }
  }
  .p-dock.p-dock-right {
    .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
      .p-dock-list {
        margin: auto 0;
      }
    }
  }
  .p-dock {
    .p-dock-list {
      .p-dock-item {
        transform: none;
        margin: 0;
      }
    }
  }
  .p-menubar {
    position: relative;
    .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: $color_16;
      border-radius: 50%;
      transition: box-shadow 0.15s;
      &:hover {
        color: $color_17;
        background: transparent;
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;

      }
    }
    .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.5rem 0;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: none;
      width: 100%;
      .p-menu-separator {
        border-top: 1px solid $border-color;
        margin: 0.5rem 0;
      }
      .p-submenu-icon {
        font-size: 0.875rem;
      }
      .p-menuitem {
        width: 100%;
        position: static;
        .p-menuitem-link {
          padding: 0.75rem 1rem;
          color: $color_3;
          border-radius: 0;
          transition: box-shadow 0.15s;
          user-select: none;
          .p-menuitem-text {
            color: $color_3;
          }
          .p-menuitem-icon {
            color: $color_3;
            margin-right: 0.5rem;
          }
          .p-submenu-icon {
            color: $color_3;
          }
          &:not(.p-disabled) {
            &:hover {
              background: #e9ecef;
              .p-menuitem-text {
                color: $color_3;
              }
              .p-menuitem-icon {
                color: $color_3;
              }
              .p-submenu-icon {
                color: $color_3;
              }
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
          }
          .p-submenu-icon {
            margin-left: auto;
            transition: transform 0.15s;
          }
        }
      }
      .p-menuitem.p-menuitem-active {
        .p-menuitem-link {
          .p-submenu-icon {
            transform: rotate(-180deg);
          }
        }
      }
      .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
        .p-submenu-icon {
          transition: transform 0.15s;
          transform: rotate(90deg);
        }
        .p-menuitem-active {
          .p-menuitem-link {
            .p-submenu-icon {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .p-menuitem {
        width: 100%;
        position: static;
      }
      ul {
        li {
          a {
            padding-left: 2.25rem;
          }
          ul {
            li {
              a {
                padding-left: 3.75rem;
              }
              ul {
                li {
                  a {
                    padding-left: 5.25rem;
                  }
                  ul {
                    li {
                      a {
                        padding-left: 6.75rem;
                      }
                      ul {
                        li {
                          a {
                            padding-left: 8.25rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .p-menubar.p-menubar-mobile-active {
    .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
}
