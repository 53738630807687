@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '@ctrl/ngx-emoji-mart/picker';
// @import '../node_modules/@ng-select/ng-select/themes/default.theme.css';

$colorPrimary: theme-color('primary');
$colorPrimaryDark: darken($colorPrimary, 50%);
$colorPrimaryLight: lighten($colorPrimary, 50%);

.rounded-top-left{
  border-top-left-radius:0.357rem !important
} 

.rounded-top-right{
  border-top-right-radius:0.357rem !important
}

.rounded-bottom-left{
  border-bottom-left-radius:0.357rem !important
}

.rounded-bottom-right{
  border-bottom-right-radius:0.357rem !important
}

.on-hover:hover {
  text-decoration: underline;
}

.p-datepicker-calendar{
  tbody{
    tr{
      td{
        span.p-ripple.p-element {
          overflow: visible!important;
          position: relative!important;
        }

        p-badge{
          span.p-badge{
            position: absolute !important;
            z-index: 1;
            width: 20px !important;
            height: 20px !important;
            display: flex !important;
            justify-content: center !important;
            border-radius: 100% !important;
            top: -8px !important;
            right: -7px;
            background: #007bff !important;
            color: white !important;
          }
        }
      }
    }
  }
}

.p-dialog {
  &.full-screen-dialog {
    border-radius: 0 !important;

    .p-dialog-header {
      border-radius: 0 !important;

      .p-dialog-title {
        font-weight: 500;
        font-size: 1.714rem;
      }
    }
  }
}

img[alt='datatable-avatar'],
.avatar,
p-avatar img {
  object-fit: cover !important;
}

.custom-tooltip {
  width: 250px;
}

.custom-tooltip .header {
  padding: 10px;
  background: #ebebeb;
  font-weight: 600;
  color: #1b1b1b !important;
}

.custom-tooltip .item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-series {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  margin-right: 10px;
}

.ft-13 {
  font-size: 13px;
}

.ft-11 {
  font-size: 11px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.dot-outer {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  padding: 8px;
  text-align: center;
  border-radius: 100%;
  margin-right: 5px;

  background-color: #ffffff;
  box-shadow: #dcdcdc 1px 5px 10px;

  &.green {
    // background-color: #b7eeb7;
    // background-color: var(--color-success-transparent);
  }
  &.orange {
    // background-color: #ffd3aa;
    // background-color: var(--color-warning-transparent);
  }
  &.yellow {
    // background-color: #fff3cd;
    // background-color: var(--color-yellow-transparent);
  }
  &.red {
    // background-color: #ffbbbb;
    // background-color: var(--color-primary-transparent);
  }

  .bg-light-yellow {
    color: #fff3cd !important;
    background-color: var(--color-yellow-transparent) !important;
  }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 4px;
    left: 4px;

    &.green {
      background-color: var(--color-success-light);
    }
    &.orange {
      background-color: var(--color-warning-light);
    }
    &.yellow {
      background-color: var(--color-yellow-light);
    }
    &.red {
      background-color: var(--color-primary-light);
    }
  }
}

.badge-yellow {
  background-color: var(--color-planning) !important;
}
.badge-light-yellow {
  color: var(--color-yellow-light) !important;
  background-color: var(--color-yellow-transparent) !important;
}
::-webkit-scrollbar-track {
  background-color: #f3f3f3;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(138, 139, 145, 0.6);
  border-radius: 50px;
}

.alert-yellow {
  background: rgb(255 239 67 / 12%) !important;
  color: #ffd543 !important;
}

.collapse-icon {
  [aria-expanded='true']:after,
  [aria-expanded='false']:after {
    top: inherit !important;
    margin-top: 0;
  }
}

.tanoko-small {
  width: 20px !important;
  height: 20px;
  font-size: 11px;
  padding: 0 !important;
  margin-left: 10px;
  border-radius: 2px !important;
}

.tanoko-symbol {
  display: inline-block;
  border: 2px solid #d6d9df;
  aspect-ratio: 1/1;
  margin-left: 5px;
  padding: 5px;
  width: 35px;
  text-align: center;
  border-radius: 3px;
}

.tanoko-1 {
  border-top: 3px solid var(--color-success);
}
.tanoko-2 {
  border-top: 3px solid var(--color-success);
  border-left: 3px solid var(--color-success);
}
.tanoko-3 {
  border-top: 3px solid var(--color-success);
  border-left: 3px solid var(--color-success);
  border-bottom: 3px solid var(--color-success);
}
.tanoko-4 {
  border-top: 3px solid var(--color-success);
  border-left: 3px solid var(--color-success);
  border-bottom: 3px solid var(--color-success);
  border-right: 3px solid var(--color-success);
}

.dot-indicator {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: var(--primary);
  border-radius: 50px;
  margin-right: 5px;
}

.close-modal {
  border: 0;
  position: absolute;
  padding: 6px 8px;
  box-shadow: 0px 5px 15px rgb(58 57 57 / 7%);
  margin: 8px;
  top: -20px;
  right: -20px;
  background: white;
  border-radius: 100%;
}

.init-filter-section {
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f7f7f7;
}

.text-underline {
  text-decoration: underline;
}

.block-ui-spinner,
.block-ui-template {
  text-align: center !important;
  position: absolute !important;
  top: 50% !important;
  margin: 0 auto !important;
  left: 0 !important;
  transform: translateY(-50%) !important;
}

.badge-status-success {
  background-color: #e0f4e0;
  color: var(--success);
}

.badge-status-warning {
  background-color: #fff0e2;
  color: var(--warning);
}

.badge-status-danger {
  background-color: #fae7e7;
  color: var(--primary);
}

a {
  color: $colorPrimaryLight;

  &:hover {
    color: $colorPrimary;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.block-ui-spinner,
.block-ui-template {
  text-align: center !important;
  position: absolute !important;
  top: 50% !important;
  margin: 0 auto !important;
  left: 0 !important;
  transform: translateY(-50%) !important;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.text-black {
  color: #1b1b1b;
}

.text-success-darken {
  color: #51a351 !important;
}

.text-info-darken {
  color: #0699ab !important;
}

.text-info-default {
  color: #07b2c7 !important;
}

.text-warning-darken {
  color: #f97900 !important;
}

.text-warning-light-2 {
  color: #ffb46c !important;
}

.text-secondary-transparent {
  color: #d3e0e8 !important;
}

.text-primary-light {
  color: #ea5455 !important;
}

.text-primary-light-2 {
  color: #e88787 !important;
}

.text-secondary-darken {
  color: #6c757d !important;
}

.text-yellow-darken {
  color: #ffc90d !important;
}

.text-secondary-light {
  color: #909ca7 !important;
}

.text-secondary-light-2 {
  color: #a8b5c1 !important;
}

.bg-secondary-light {
  background-color: #909ca7 !important;
}

.bg-secondary-light-2 {
  background-color: #a8b5c1 !important;
}

.bg-secondary-transparent {
  background-color: #d3e0e8 !important;
}

.bg-primary-transparent {
  background-color: #fae7e7 !important;
}

.bg-success-transparent {
  background-color: #e0f4e0 !important;
}

.bg-info-transparent {
  background-color: #e5fbfe !important;
}

.bg-warning-transparent {
  background-color: #fff0e2 !important;
}

.bg-grey-accent {
  background-color: #e8e8e8 !important;
}

.ngx-datatable.bootstrap.core-bootstrap {
  .datatable-header {
    .sort-btn:before {
      float: right;
    }
  }
}

.timepicker-overlay {
  z-index: 10000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000 !important;
}
.timepicker {
  .timepicker__header {
    background-color: var(--primary) !important;
  }

  .clock-face__clock-hand {
    background-color: var(--primary) !important;

    &::after {
      background-color: var(--primary) !important;
    }

    &::before {
      border-color: var(--primary) !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: var(--primary) !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: var(--primary) !important;
  }
}

.color-picker {
  border: 0 !important;
  box-shadow: 5px 5px 20px rgb(201, 201, 201, 0.5);
}
.theme-green .bs-datepicker-head {
  background-color: var(--primary) !important;
}

.theme-green .bs-datepicker-body table td.week span {
  color: var(--primary) !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*='select-']:after,
.theme-green .bs-datepicker-body table td[class*='select-'] span:after {
  background-color: var(--primary) !important;
}

.p-datepicker table td span.p-highlight {
  background-color: var(--primary) !important;
}

.p-inputtext:enabled:focus {
  border-color: var(--secondary) !important;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  background-color: var(--primary);
}

//.form-control {
//  &:focus {
//    border-color: inherit !important;
//  }
//}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-left: none;
  padding-left: 0;
}

.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: inherit !important;
}

.content-header-title {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234b4b4b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  }
}

.fa {
  width: 14px;
  height: 14px;
}

.scroll-container {
  background-color: #f3f2f7 !important;
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.dotty-effect {
  h3 {
    margin-right: 5px;
  }

  &::after {
    animation: dotty steps(1, end) 2s infinite;
    content: '';
    font-size: 1.5rem;
  }
}

.spinner-arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 6px;
  background: conic-gradient(from 135deg at top, currentColor 90deg, #0000 0) 0
      calc(50% - 4px) / 17px 8.5px,
    radial-gradient(
        farthest-side at bottom left,
        #0000 calc(100% - 6px),
        currentColor calc(100% - 5px) 99%,
        #0000
      )
      top right/50% 50% content-box content-box,
    radial-gradient(
        farthest-side at top,
        #0000 calc(100% - 6px),
        currentColor calc(100% - 5px) 99%,
        #0000
      )
      bottom / 100% 50% content-box content-box;
  background-repeat: no-repeat;
  animation: loaderArrow 1s infinite linear;
}

@keyframes loaderArrow {
  100% {
    transform: rotate(1turn);
  }
}

.spinner-process {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: loaderProcess 1s infinite linear;
}

@keyframes loaderProcess {
  100% {
    transform: rotate(1turn);
  }
}

.progress-bar-success {
  background-color: #e0f4e0;
}

//new layout
.main-menu {
  background-color: #eee !important;
  border-right: 1px solid $border-color;

  .navigation {
    background: inherit !important;
  }
}

.header-navbar {
  background-color: $navbar-color;
}

.app-content {
  background: $white;

  .content-header {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
    height: 5rem;
    align-items: center;
  }

  .content-body {
    overflow-y: auto;
    position: absolute;
    right: 0;
    left: 0;
    top: 9.47rem;
    bottom: 0;
    min-height: calc(100vh - 9.45rem);
    background: $white;
    //padding-right: 5px;

    &.dashboard {
      height: calc(100vh - 5rem);
      top: 4.5rem;

      iframe {
        width: 100%;
        height: calc(100vh - 6rem);
      }
    }

    .card {
      .card-header {
        background-color: $navbar-color;
      }

      .card-footer {
        &.fixed-actions {
          width: calc(100%);
          position: fixed;
          bottom: 0;
          background: $white;
          box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
          z-index: 1;
          height: 5.5rem;
        }
      }

      .card-legend {
        display: flex;
        position: absolute;
        background-color: $white;
        margin-top: -8px;
        margin-left: 8px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.timepicker-select {
  .time-type {
    padding: 0;
    max-width: 60px;
    min-width: 60px;

    .ng-select-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .timepicker {
    padding: 0;
    max-width: 110px;
    min-width: 110px;

    .ng-select-container {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.text-status {
  &.planning {
    color: $color-planning;
  }

  &.pending {
    color: $color-pending;
  }

  &.on-progress {
    color: $color-on-progress;
  }

  &.delivered {
    color: $color-delivered;
  }

  &.completed,
  &.off {
    color: $color-completed;
  }

  &.delay,
  &.absent,
  &.late,
  &.canceled {
    color: $danger;
  }

  &.on-time {
    color: $success;
  }

  &.ontime {
    color: $success;
  }

  &.advance,
  &.leave,
  &.open {
    color: $info;
  }
}

.content-header-left {
  .nav-item {
    h2 {
      margin-bottom: 0;
    }
  }
}

.content-header-right {
  .close {
    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.modal-footer {
  justify-content: flex-start;
}

.table {
  tr {
    &.bg-light-secondary {
      td {
        border-color: $white;
      }
    }
  }
}

.loader-horizontal {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $body-color;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.p-datatable {
  .p-datatable-table {
    //display: inline-block;
  }

  .p-datatable-loading-overlay {
    .p-datatable-loading-icon {
      display: none !important;
    }
  }
}

//fixed form color invalid
ng-select {
  &.is-invalid {
    .ng-select-container {
      border-color: $danger;
    }
  }
}

.is-invalid {
  .custom-control {
    .custom-control-label {
      &::after,
      &::before {
        border-color: $danger;
      }
    }
  }
}

.table-monitoring {
  th,
  td {
    padding: 1rem 1rem;
  }
}

.p-datepicker {
  table {
    th,
    td {
      padding: 0.2rem;
      text-align: center;
    }
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    border: none;
    padding-bottom: 5px;

    .p-tabmenuitem {
      margin-right: 10px;

      .p-menuitem-link {
        border: 1px solid #6c757d !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1 !important;
        border-radius: 0.358rem !important;
        color: #6c757d !important;
        white-space: nowrap;

        &:focus {
          box-shadow: none !important;
        }
      }

      &.p-highlight {
        .p-menuitem-link {
          color: $primary !important;
          border-color: $primary !important;
          box-shadow: none !important;
          background-color: $dropdown-link-hover-bg;
        }
      }
    }
  }
}

.p-calendar {
  .p-inputtext {
    width: 100px;
    border-color: #d8d6de;
  }

  .p-button {
    background-color: transparent;
    color: #6e6b7b;
    border-color: #d8d6de;
    border-left: none;

    &:hover {
      background: transparent;
      color: #6e6b7b;
      border-color: #d8d6de;
    }
  }

  &.p-focus {
    .p-inputtext {
      border-color: #ebe9f1 !important;
    }

    .p-button {
      border-color: var(--secondary) !important;
    }
  }
}

.swal2-container {
  z-index: 12010 !important;
}

.range-month-year,
.month-year {
  .p-calendar {
    display: flex !important;
  }
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  background-color: var(--primary) !important;
}

.mr-30 {
  margin-right: 0.3rem;
}

$base-line-height: 40px;
$color: #51a351;
$off-color: #e0f4e0;
$pulse-duration: 750ms;

@keyframes pulse {
  50% {
    background: $color;
  }
}

.loading-pulse {
  position: relative;
  width: ($base-line-height / 4);
  height: $base-line-height;
  background: $off-color;
  animation: pulse $pulse-duration infinite;
  animation-delay: ($pulse-duration / 3);
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: ($base-line-height / 1.5);
    width: ($base-line-height / 4);
    background: $off-color;
    top: 50%;
    transform: translateY(-50%);
    animation: pulse $pulse-duration infinite;
  }
  &:before {
    left: -calc($base-line-height / 2);
  }
  &:after {
    left: ($base-line-height / 2);
    animation-delay: ($pulse-duration / 1.5);
  }
}
.p-dialog-header,
.p-dialog-footer {
  border: 0 !important;
}

.wobbling-3 {
  width: 80px;
  height: 15px;
  border-radius: 31px;
  color: #51a351;
  border: 2px solid;
  position: relative;
}
.wobbling-3::before {
  content: '';
  position: absolute;
  margin: 2px;
  width: 25%;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: currentColor;
  animation: w3 2s infinite linear;
}
@keyframes w3 {
  50% {
    left: 100%;
    transform: translateX(calc(-100% - 4px));
  }
}


.pulse-dot {

    display: block;
    width: 10px;
    height: 10px;
    font-weight: light;
    border: none;
    border-radius: 50px;
    background: var(--color-primary);
    box-shadow: 0 0 0 0 rgba(#c0262d, .5);
    animation: pulse-dot 1.5s infinite;
}


@keyframes pulse-dot {
  0% {
    transform: scale(.5);
  }
  70% {
    transform: scale(.8);
    box-shadow: 0 0 0 10px rgba(#c0262d, 0);
  }
    100% {
    transform: scale(.5);
    box-shadow: 0 0 0 0 rgba(#c0262d, 0);
  }
}
